import { useEffect, useState } from "react";
import { ExcalidrawImperativeAPI } from "../../../../packages/excalidraw/types";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import React from "react";
import { currentTabAtom, Tabs, updateTab } from "../../../store/tabList";
import { useAtom, useAtomValue } from "jotai";
import { appJotaiStore } from "../../../app-jotai";
import { TabMetaData } from "../../../types/tabs";
import usePreviousPage from "../hooks/usePreviousPage"

interface IFooterUIProps {
  // totalPages:number;
  // cPage:number;
  onPageChange: (currentPage: number) => void;
}

const FooterUI = ({ onPageChange }: IFooterUIProps) => {
  const currentTab = useAtomValue(currentTabAtom);
  const [currentPage, setCurentPage] = useState(currentTab?.pageIndex ?? 0);
  const previousPage = usePreviousPage(currentPage);

  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const totalPages = Tabs.get(currentTab?.tabId!)?.pages?.length ?? 1;
    setTotalPages(totalPages);
    setCurentPage(currentTab?.pageIndex ?? 0);
  }, [currentTab]);

  const [options, setOptions] = useState<Array<JSX.Element>>();
  const [disablePre, setDisablePre] = useState(true);
  const [disableNext, setDisableNext] = useState(false);
  useEffect(() => {
    const element: Array<JSX.Element> = [];
    for (let i = 0; i < totalPages; i++) {
      element.push(
        <option key={i} value={i}>
          Page {i}
        </option>,
      );
    }
    setOptions(element);
  }, [totalPages]);

  useEffect(() => {
    if(previousPage && currentPage!=previousPage) {
      onPageChange(currentPage);
    }
  }, [currentPage]);

  const handlePre = () => {
    console.log("going previous..");
    setCurentPage(currentPage - 1);
  };

  const handleNext = () => {
    console.log("going next..");

    setCurentPage(currentPage + 1);
  };

  useEffect(() => {
    if (currentPage == 0) {
      setDisablePre(true);
    } else {
      setDisablePre(false);
    }

    if (currentPage == totalPages) {
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }
  }, [currentPage]);

  return currentTab?.type != "officeFile" ? null : (
    <div>
      <IconButton onClick={handlePre} disabled={disablePre}>
        <ArrowBackIcon />
      </IconButton>

      <select
        id="pages"
        name="pages"
        onChange={(e) => setCurentPage(Number(e.currentTarget.value))}
        value={currentPage}
      >
        {options}
      </select>

      <IconButton onClick={handleNext} disabled={disableNext}>
        <ArrowForwardIcon />
      </IconButton>
    </div>
  );
};

export default React.memo(FooterUI);
