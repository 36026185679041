import { useAtom, useAtomValue } from "jotai"; // useAtom to read and update the visibility atom
import {
  isParticipantListVisibleAtom,
  participantListAtom,
} from "../../store/participantList"; // Import the atom for visibility
import "./ParticipantComponent.scss"; // Ensure this file contains the necessary styles
import React, { useState } from "react";
import { IconButton, List } from "@mui/material";

import { ParticipantListItem } from "./_components/ParticipantListItem";
import Close from "@mui/icons-material/Close";

export const ParticipantComponent = () => {
  const [isVisible, setIsVisible] = useAtom(isParticipantListVisibleAtom); // Read and write visibility atom

  const participants = useAtomValue(participantListAtom);
  return (
    <>
      <div className={`participant-container ${isVisible ? "visible" : ""}`}>
        <div className="participant-header">
          <h2>Participants</h2>
          <IconButton onClick={() => setIsVisible(false)}>
            <Close />
          </IconButton>
         
        </div>

        <List>
          {Array.from(participants.values()).map((participant) => (
            <ParticipantListItem key={participant.userId} participant={participant} />
          ))}
        </List>
      </div>

      {isVisible && (
        <div className="overlay" onClick={() => setIsVisible(false)}></div>
      )}
    </>
  );
};
