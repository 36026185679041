import { Avatar } from "@mui/material";
import { useAtomValue } from "jotai";
import type { RemoteAudioTrack } from "livekit-client";
import { useEffect, useRef } from "react";
import React from "react";
import { currentSessionAtom } from "../../../../store/session";

interface IAudioElementProps {
  audioTrack: RemoteAudioTrack;
  userId: string;
}

const AudioElm = ({ audioTrack, userId }: IAudioElementProps) => {
  const ref = useRef<HTMLAudioElement>(null);
  const user = useAtomValue(currentSessionAtom)?.user;

  useEffect(() => {
    const el = ref.current;
    if (el && user?.userId!=userId) {
      audioTrack?.attach(el);
      // if (typeof participant.audioVolume !== 'undefined') {
      // audioTrack.setVolume(1);
      // }
    }

    return () => {
      if (el) {
        audioTrack.detach(el);
      }
    };
    //eslint-disable-next-line
  }, [audioTrack]);

  return (
    

      <div style={{ display: "none" }}>
        <audio autoPlay ref={ref} />
      </div>
   
  );
};

export default React.memo(AudioElm);
