import IconButton from "@mui/material/IconButton";

import React from "react";

import { useSetAtom } from "jotai";
import { isParticipantListVisibleAtom } from "../../../store/participantList";
import { Info } from "@mui/icons-material";

function InfoIcon() {

  const togglePeopleList = useSetAtom(isParticipantListVisibleAtom); // Set the atom to toggle visibility

  return (
    <IconButton
      style={{
        // border: "1px solid #ccc",
        background: "#ccc",
        marginLeft: "5px",
      }}
      size="large"
      onClick={() => togglePeopleList(prev => !prev)} // Toggle the visibility 
    >
      <Info />
      
    </IconButton>
  );
}

export default InfoIcon;
