import { atom } from "jotai";
import { Page, Tab, TabMetaData } from "../types/tabs";
import { appJotaiStore } from "../app-jotai";
import { randomId } from "../../packages/excalidraw/random";

export const tabListAtom = atom<TabMetaData[]>([]);
export const Tabs = new Map<string, Tab>(new  Map());

export const currentTabAtom = atom<TabMetaData | null>(null);
export const whiteboardLoadingAtom = atom<boolean>(false);
// Function to add a new tab to the array
export const addTab = (tab: TabMetaData) => {
  console.log("tab added... ", tab);
  appJotaiStore.set(tabListAtom, (prev) => {
    return [...prev, tab]; // Add new tab to the array
  });
};

// Function to update a tab by its id
export const updateTab = (id: string, changes: Partial<TabMetaData>) => {
  appJotaiStore.set(tabListAtom, (prev) => {
    return prev.map((tab) => {
      if (tab.tabId === id) {
        const updatedTab = { ...tab, ...changes };

        // If this tab is the currentTab, also update currentTabAtom
        if (appJotaiStore.get(currentTabAtom)?.tabId === id) {
          appJotaiStore.set(currentTabAtom, updatedTab);
        }

        return updatedTab;
      }
      return tab;
    });
  });
};

export const setCurrentTab = (newTab: TabMetaData) => {
  // Update currentTabAtom
  appJotaiStore.set(currentTabAtom, newTab);

  // Update the tab in tabListAtom as well
  appJotaiStore.set(tabListAtom, (prev) => {
    return prev.map((tab) => (tab.tabId === newTab.tabId ? newTab : tab));
  });
};

export const removeTab = (id: string) => {
  appJotaiStore.set(tabListAtom, (prev) => {
    const updatedTabs = prev.filter((tab) => tab.tabId !== id);
    
    // If the current tab is the one being removed, reset currentTabAtom
    if (appJotaiStore.get(currentTabAtom)?.tabId === id) {
      appJotaiStore.set(currentTabAtom, updatedTabs.length > 0 ? updatedTabs[0] : null);
    }

    return updatedTabs;
  });
};

export const getTabByIdAtom = (tabId: string) => atom(
  (get) => get(tabListAtom).find((tab) => tab.tabId === tabId) || null
);







export const createTabMetadata = ():TabMetaData => {
  const tab: TabMetaData = {
    title: `Board new`,
    type: "normal",
    tabId: randomId(),
    pageIndex:0
  };
  return tab;
};