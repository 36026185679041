import React from "react";
import { useEffect, useState } from "react";

interface GridLayoutProps {
  participants: JSX.Element[];
  total: number;
  parentRef: React.RefObject<HTMLDivElement>;
  // containerHeight:number,
  // contanerWidth
}

export const GridLayout = ({
  participants,
  total,
  parentRef,
}: GridLayoutProps) => {
  const [containerWidth, setContainerWidth] = useState(window.innerWidth);
  const [containerHeight, setContainerHeight] = useState(window.innerHeight);
  const [layout, setLayout] = useState({
    nrows: 1,
    ncols: 1,
    itemWidth: 0,
    itemHeight: 0,
  });

  const videoAspectWidth = 16;
  const videoAspectHeight = 9;

  useEffect(() => {
    const newLayout = fitToContainer(
      total,
      containerWidth,
      containerHeight,
      videoAspectWidth,
      videoAspectHeight,
    );
    setLayout(newLayout);
  }, [containerWidth, containerHeight, total]);

  useEffect(() => {
    const parent = parentRef.current;
    if (!parent) {
      return;
    }
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setContainerWidth(entry.contentRect.width);
        setContainerHeight(entry.contentRect.height);

        // setDimensions({
        //   width: entry.contentRect.width,
        //   height: entry.contentRect.height,
        // });
      }
    });

    resizeObserver.observe(parent);
    return () => {
      resizeObserver.unobserve(parent);
    };
  }, [parentRef]);

  return (
    <div
      className="grid-container"
      style={{ height: `${containerHeight}px`, width: `${containerWidth}px` }}
    >
      <div
        className="grid-class"
        style={{
          gridTemplateColumns: `repeat(${layout.ncols}, ${layout.itemWidth}px)`,
          gridTemplateRows: `repeat(${layout.nrows}, ${layout.itemHeight}px)`,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {participants.map((videoBox, index) => (
          <div
            key={index}
            style={{
              width: `${layout.itemWidth}px`,
              height: `${layout.itemHeight}px`,
              backgroundColor: "black",
              position: "relative",
              borderRadius: "10px",
              overflow: "hidden",
              // padding:"5px"
            }}
          >
            {videoBox}
          </div>
        ))}
      </div>
    </div>
  );
};

function fitToContainer(
  n: number,
  containerWidth: number,
  containerHeight: number,
  itemWidth: number,
  itemHeight: number,
) {
  if (n === 0) {
    n = 1;
  }

  const ratio =
    (containerWidth = (containerWidth * itemHeight) / itemWidth) /
    containerHeight;
  const ncols_float = Math.sqrt(n * ratio);
  const nrows_float = n / ncols_float;
  let nrows1 = Math.ceil(nrows_float);
  let ncols1 = Math.ceil(n / nrows1);

  while (nrows1 * ratio < ncols1) {
    nrows1++;
    ncols1 = Math.ceil(n / nrows1);
  }

  const cell_size1 = containerHeight / nrows1;
  let ncols2 = Math.ceil(ncols_float);
  let nrows2 = Math.ceil(n / ncols2);

  while (ncols2 < nrows2 * ratio) {
    ncols2++;
    nrows2 = Math.ceil(n / ncols2);
  }

  const cell_size2 = containerWidth / ncols2;
  const nrows = cell_size1 < cell_size2 ? nrows2 : nrows1;
  const ncols = cell_size1 < cell_size2 ? ncols2 : ncols1;
  const cell_size = cell_size1 < cell_size2 ? cell_size2 : cell_size1;

  return {
    nrows,
    ncols,
    itemWidth: (cell_size * itemWidth) / itemHeight,
    itemHeight: cell_size,
  };
}
