import throttle from "lodash.throttle";
import { PureComponent } from "react";
import type {
  ExcalidrawImperativeAPI,
  SocketId,
} from "../../../../packages/excalidraw/types";
import { ErrorDialog } from "../../../../packages/excalidraw/components/ErrorDialog";
import {
  APP_NAME,
  ENV,
  EVENT,
} from "../../../../packages/excalidraw/constants";
import type { ImportedDataState } from "../../../../packages/excalidraw/data/types";
import type {
  ExcalidrawElement,
  InitializedExcalidrawImageElement,
  OrderedExcalidrawElement,
} from "../../../../packages/excalidraw/element/types";
import {
  StoreAction,
  getSceneVersion,
  restoreElements,
  zoomToFitBounds,
  reconcileElements,
} from "../../../../packages/excalidraw";
import type {
  Collaborator,
  Gesture,
} from "../../../../packages/excalidraw/types";
import {
  assertNever,
  preventUnload,
  resolvablePromise,
  throttleRAF,
} from "../../../../packages/excalidraw/utils";
import {
  CURSOR_SYNC_TIMEOUT,
  FILE_UPLOAD_MAX_BYTES,
  FIREBASE_STORAGE_PREFIXES,
  INITIAL_SCENE_UPDATE_TIMEOUT,
  LOAD_IMAGES_TIMEOUT,
  WS_SUBTYPES,
  SYNC_FULL_SCENE_INTERVAL_MS,
  WS_EVENTS,
  FILE_UPLOAD_TIMEOUT,
} from "../../../app_constants";
import type {
  SocketUpdateDataSource,
  SyncableExcalidrawElement,
} from "../data";
import {
  generateCollaborationLinkData,
  getCollaborationLink,
  getSyncableElements,
  isSyncableElement,
} from "../data";

import {
  importUsernameFromLocalStorage,
  saveUsernameToLocalStorage,
} from "../data/localStorage";
import Portal from "./Portal";
import { t } from "../../../../packages/excalidraw/i18n";
import { UserIdleState } from "../../../../packages/excalidraw/types";
import {
  IDLE_THRESHOLD,
  ACTIVE_THRESHOLD,
} from "../../../../packages/excalidraw/constants";
import {
  encodeFilesForUpload,
  FileManager,
  updateStaleImageStatuses,
} from "../data/FileManager";
import { AbortError } from "../../../../packages/excalidraw/errors";
import {
  isImageElement,
  isInitializedImageElement,
} from "../../../../packages/excalidraw/element/typeChecks";
import { newElementWith } from "../../../../packages/excalidraw/element/mutateElement";
import { decryptData } from "../../../../packages/excalidraw/data/encryption";
import { resetBrowserStateVersions } from "../data/tabSync";
import { LocalData } from "../data/LocalData";
import { atom, useAtomValue } from "jotai";
import { appJotaiStore } from "../../../app-jotai";
import type {
  Mutable,
  ValueOf,
} from "../../../../packages/excalidraw/utility-types";
import type { SceneBounds } from "../../../../packages/excalidraw/element/bounds";
import { getVisibleSceneBounds } from "../../../../packages/excalidraw/element/bounds";
import { withBatchedUpdates } from "../../../../packages/excalidraw/reactUtils";
import { collabErrorIndicatorAtom } from "./CollabError";
import type {
  ReconciledExcalidrawElement,
  RemoteExcalidrawElement,
} from "../../../../packages/excalidraw/data/reconcile";
import React from "react";

import {
  loadFilesFromStorage,
  loadFromBackend,
  saveFilesToStorage,
} from "../data/storage";
import type {
  MqttMessage,
  MqttWhiteboardPayload,
  MqttWhiteboardPointsPayload,
  MqttWhiteboardTabPayload,
} from "../../../types/whiteboard";
import { MousePointerUpdate, MqttMessageType } from "../../../types/whiteboard";
import MqttService, { getMqttConn } from "../../../utils/mqttClient";
import { addTab, createTabMetadata, currentTabAtom, getTabByIdAtom, removeTab, setCurrentTab, Tabs } from "../../../store/tabList";
import { Page, Tab, TabMetaData } from "../../../types/tabs";
// export const collabAPIAtom = atom<CollabAPI | null>(null);
// export const isCollaboratingAtom = atom(false);
export const isOfflineAtom = atom(false);



interface CollabProps {
  excalidrawAPI: ExcalidrawImperativeAPI;
  userId: string;
  roomId: string;
}

class Collab {
  // portal: Portal;
  userId: string;
  roomId: string;
  fileManager: FileManager;
  excalidrawAPI: CollabProps["excalidrawAPI"];

  private lastBroadcastedOrReceivedSceneVersion: number = -1;
  private collaborators = new Map<SocketId, Collaborator>();
  broadcastedElementVersions: Map<string, number> = new Map();
  client: MqttService;

  constructor(props: CollabProps) {
    // super(props);

    this.fileManager = new FileManager({
      getFiles: async (fileIds) => {
        return loadFilesFromStorage(
          `${this.roomId}/whiteboard/images`,
          "mFyBK_G5m6F-sK1reDiLnA",
          fileIds,
        );
      },
      saveFiles: async ({ addedFiles }) => {
        console.log("save files chal rha hai....");
        return saveFilesToStorage({
          prefix: `${this.roomId}/whiteboard/images`,
          files: await encodeFilesForUpload({
            files: addedFiles,
            encryptionKey: "mFyBK_G5m6F-sK1reDiLnA",
            maxBytes: FILE_UPLOAD_MAX_BYTES,
          }),
        });
      },
    });
    this.userId = props.userId;
    this.roomId = props.roomId;
    this.excalidrawAPI = props.excalidrawAPI;
    this.client = getMqttConn();
  }

  onSubcribe = (payload: Buffer) => {
    const mese = payload.toString().split("/")[1];
    const message = JSON.parse(mese);
    // const currentUser = store.getState().session.currentUser;
    const tabData = JSON.parse(message.data);
    console.log("recieved message on remote side...");

    if (this.userId == message.userId) {
      return;
    }

    if (message.type === MqttMessageType.SCENE_UPDATE) {
      console.log("SCENE_UPDATE board recieved: ", tabData);

      // const elements = JSON.parse(tabData.points);

    const currentTab = appJotaiStore.get(currentTabAtom);

      
    if(currentTab?.tabId == tabData.tabId) {
      this.handleRemoteSceneUpdate(this._reconcileElements(tabData.points));

    }else{
      console.log("not the same tab...")
    }

    }
    // if (message.type === MqttMessageType.SWITCH_TAB) {
    //   // const tabData = JSON.parse(message.data);
    //   console.log("switch tab recieved: ", tabData);


    //   const currentTab = appJotaiStore.get(getTabByIdAtom(tabData.tabId));

    //   if(currentTab) {
    //     appJotaiStore.set(currentTabAtom,currentTab)
    //   }

    //   // store.dispatch(setCurrentBoard(tabData.id));
    // }

    // if(message.type === MqttMessageType.DELETE_TAB) {
    //   console.log("delete tab recieved...",tabData);

    //   removeTab(tabData.tabId);
    // }

    // if (message.type == MqttMessageType.CREATE_TAB) {
    //   // const tabData = JSON.parse(message.data);
    //   console.log("create tab recieved: ", tabData);

    //   const tabmeta:TabMetaData = {
    //     tabId:tabData.tabId,
    //     type:tabData.type,
    //     title: tabData.title,
    //     pageIndex:tabData.pageIndex
    //   }

    //   addTab(tabmeta);
      
    //   const page: Page = {
    //     points: [],
    //     index: 0,
    //   };
    //   const tab: Tab = {
    //     type: tabmeta.type,
    //     pages: [page],
    //     tabId: tabmeta.tabId,
    //   };
    //   Tabs.set(tab.tabId, tab);

    //   setCurrentTab(tabmeta)

    //   // Tabs.set(tab.tabId, tab);
    //   // store.dispatch(addBoard(tabData));
    // }

    if (message.type == MqttMessageType.POINTER_UPDATE) {
      console.log("pointer update arrived: ", tabData);

      const updates = JSON.parse(
        tabData.mousePointerLocation,
      ) as Collaborator;

      this.updateCollaborator(message.userId, updates);
    }

    if (message.type == MqttMessageType.USER_VISIBLE_SCENE_BOUNDS) {
      const sceneBounds = JSON.parse(tabData.sceneBounds);

      const appState = this.excalidrawAPI.getAppState();

      if (
        appState.userToFollow &&
        appState.followedBy.has(appState.userToFollow.socketId)
      ) {
        return;
      }

      this.excalidrawAPI.updateScene({
        appState: zoomToFitBounds({
          appState,
          bounds: sceneBounds,
          fitToViewport: true,
          viewportZoomFactor: 1,
        }).appState,
      });
    }

    if (message.type == MqttMessageType.USER_FOLLOW_CHANGE) {
    }

    if (message.type == MqttMessageType.WHITEBOARD_APP_STATE_CHANGE) {
      // console.log("WHITEBOARD_APP_STATE_CHANGE:: currentuser: ", tabData);
      // if (message.userId == currentUser?.userId) return;
      // if (!currentUser?.isPresenter) {
      //   console.log("present nahi hai.. update kr rhe...");
      //   store.dispatch(updateBoard({
      //     id:tabData.id,
      //     changes:{
      //       state:tabData.state
      //     }
      //   }));
      // }
    }
  };

  private onUmmount: (() => void) | null = null;

  componentDidMount() {
    // window.addEventListener(EVENT.BEFORE_UNLOAD, this.beforeUnload);
    window.addEventListener("online", this.onOfflineStatusToggle);
    window.addEventListener("offline", this.onOfflineStatusToggle);
    // window.addEventListener(EVENT.UNLOAD, this.onUnload);

    // const unsubOnUserFollow = this.excalidrawAPI.onUserFollow((payload) => {
    //   this.portal.socket && this.portal.broadcastUserFollowed(payload);
    // });
    // const throttledRelayUserViewportBounds = throttleRAF(
    //   this.relayVisibleSceneBounds,
    // );
    // const unsubOnScrollChange = this.excalidrawAPI.onScrollChange(() =>
    //   throttledRelayUserViewportBounds(),
    // );
    // this.onUmmount = () => {
    //   // unsubOnUserFollow();
    //   unsubOnScrollChange();
    // };

    console.log(
      "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx",
    );

    this.onOfflineStatusToggle();

  
  }

  onOfflineStatusToggle = () => {
    appJotaiStore.set(isOfflineAtom, !window.navigator.onLine);
  };

  componentWillUnmount() {
    window.removeEventListener("online", this.onOfflineStatusToggle);
    window.removeEventListener("offline", this.onOfflineStatusToggle);
    // window.removeEventListener(EVENT.BEFORE_UNLOAD, this.beforeUnload);
    // window.removeEventListener(EVENT.UNLOAD, this.onUnload);
    // window.removeEventListener(EVENT.POINTER_MOVE, this.onPointerMove);
    // window.removeEventListener(
    //   EVENT.VISIBILITY_CHANGE,
    //   this.onVisibilityChange,
    // );
    // if (this.activeIntervalId) {
    //   window.clearInterval(this.activeIntervalId);
    //   this.activeIntervalId = null;
    // }
    // if (this.idleTimeoutId) {
    //   window.clearTimeout(this.idleTimeoutId);
    //   this.idleTimeoutId = null;
    // }
    this.onUmmount?.();
  }

  // isCollaborating = () => appJotaiStore.get(isCollaboratingAtom)!;

  // private setIsCollaborating = (isCollaborating: boolean) => {
  //   appJotaiStore.set(isCollaboratingAtom, isCollaborating);
  // };

  // private onUnload = () => {
  //   this.destroySocketClient({ isUnload: true });
  // };

  // private beforeUnload = withBatchedUpdates((event: BeforeUnloadEvent) => {
  //   const syncableElements = getSyncableElements(
  //     this.getSceneElementsIncludingDeleted(),
  //   );

  //   if (

  //     (this.fileManager.shouldPreventUnload(syncableElements) ||
  //       !isSavedToFirebase(this.portal, syncableElements))
  //   ) {
  //     // this won't run in time if user decides to leave the site, but
  //     //  the purpose is to run in immediately after user decides to stay
  //     this.saveCollabRoomToFirebase(syncableElements);

  //     preventUnload(event);
  //   }
  // });

  // saveCollabRoomToFirebase = async (
  //   syncableElements: readonly SyncableExcalidrawElement[],
  // ) => {
  //   try {
  //     const storedElements = await saveToFirebase(
  //       this.portal,
  //       syncableElements,
  //       this.excalidrawAPI.getAppState(),
  //     );

  //     this.resetErrorIndicator();

  //     if ( storedElements) {
  //       this.handleRemoteSceneUpdate(this._reconcileElements(storedElements));
  //     }
  //   } catch (error: any) {
  //     const errorMessage = /is longer than.*?bytes/.test(error.message)
  //       ? t("errors.collabSaveFailed_sizeExceeded")
  //       : t("errors.collabSaveFailed");

  //     if (
  //       !this.state.dialogNotifiedErrors[errorMessage]

  //     ) {
  //       this.setErrorDialog(errorMessage);
  //       this.setState({
  //         dialogNotifiedErrors: {
  //           ...this.state.dialogNotifiedErrors,
  //           [errorMessage]: true,
  //         },
  //       });
  //     }

  //       this.setErrorIndicator(errorMessage);

  //     console.error(error);
  //   }
  // };

  // stopCollaboration = (keepRemoteState = true) => {
  //   this.queueBroadcastAllElements.cancel();
  //   this.queueSaveToFirebase.cancel();
  //   this.loadImageFiles.cancel();
  //   this.resetErrorIndicator(true);

  //   this.saveCollabRoomToFirebase(
  //     getSyncableElements(
  //       this.excalidrawAPI.getSceneElementsIncludingDeleted(),
  //     ),
  //   );

  //   if (this.portal.socket && this.fallbackInitializationHandler) {
  //     this.portal.socket.off(
  //       "connect_error",
  //       this.fallbackInitializationHandler,
  //     );
  //   }

  //   if (!keepRemoteState) {
  //     LocalData.fileStorage.reset();
  //     this.destroySocketClient();
  //   } else if (window.confirm(t("alerts.collabStopOverridePrompt"))) {
  //     // hack to ensure that we prefer we disregard any new browser state
  //     // that could have been saved in other tabs while we were collaborating
  //     resetBrowserStateVersions();

  //     window.history.pushState({}, APP_NAME, window.location.origin);
  //     this.destroySocketClient();

  //     LocalData.fileStorage.reset();

  //     const elements = this.excalidrawAPI
  //       .getSceneElementsIncludingDeleted()
  //       .map((element) => {
  //         if (isImageElement(element) && element.status === "saved") {
  //           return newElementWith(element, { status: "pending" });
  //         }
  //         return element;
  //       });

  //     this.excalidrawAPI.updateScene({
  //       elements,
  //       storeAction: StoreAction.UPDATE,
  //     });
  //   }
  // };

  // private destroySocketClient = (opts?: { isUnload: boolean }) => {
  //   this.lastBroadcastedOrReceivedSceneVersion = -1;
  //   this.portal.close();
  //   this.fileManager.reset();
  //   if (!opts?.isUnload) {
  //     // this.setActiveRoomLink(null);
  //     this.collaborators = new Map();
  //     this.excalidrawAPI.updateScene({
  //       collaborators: this.collaborators,
  //     });
  //     LocalData.resumeSave("collaboration");
  //   }
  // };
  private fallbackInitializationHandler: null | (() => any) = null;

  fetchImageFilesFromStorage = async (opts: {
    elements: readonly ExcalidrawElement[];
    /**
     * Indicates whether to fetch files that are errored or pending and older
     * than 10 seconds.
     *
     * Use this as a mechanism to fetch files which may be ok but for some
     * reason their status was not updated correctly.
     */
    forceFetchFiles?: boolean;
  }) => {
    // console.log("yhaa call aaya...")
    const unfetchedImages = opts.elements
      .filter((element) => {
        return (
          isInitializedImageElement(element) &&
          !this.fileManager.isFileHandled(element.fileId) &&
          !element.isDeleted &&
          (opts.forceFetchFiles
            ? element.status !== "pending" ||
              Date.now() - element.updated > 10000
            : element.status === "saved")
        );
      })
      .map((element) => (element as InitializedExcalidrawImageElement).fileId);

    return await this.fileManager.getFiles(unfetchedImages);
  };

  private decryptPayload = async (
    iv: Uint8Array,
    encryptedData: ArrayBuffer,
    decryptionKey: string,
  ): Promise<ValueOf<SocketUpdateDataSource>> => {
    try {
      const decrypted = await decryptData(iv, encryptedData, decryptionKey);

      const decodedData = new TextDecoder("utf-8").decode(
        new Uint8Array(decrypted),
      );
      return JSON.parse(decodedData);
    } catch (error) {
      window.alert(t("alerts.decryptFailed"));
      console.error(error);
      return {
        type: WS_SUBTYPES.INVALID_RESPONSE,
      };
    }
  };

  // private fallbackInitializationHandler: null | (() => any) = null;

  startCollaboration = async (roomId: string) => {
    // TODO: `ImportedDataState` type here seems abused
    const scenePromise = resolvablePromise<
      | (ImportedDataState & { elements: readonly OrderedExcalidrawElement[] })
      | null
    >();

    // const fallbackInitializationHandler = () => {
    this.initializeRoom({
      roomId,
      fetchScene: true,
    }).then((scene) => {
      scenePromise.resolve(scene);
    });
    // };
    // this.fallbackInitializationHandler = fallbackInitializationHandler;

    return scenePromise;
  };

  private initializeRoom = async ({
    fetchScene,
    roomId,
  }:
    | {
        fetchScene: true;
        roomId: string | null;
      }
    | { fetchScene: false; roomId?: null }) => {
    if (fetchScene && roomId) {
      this.excalidrawAPI.resetScene();

      console.log("initialize hone aaya..");
      try {
        const currentTabId = await loadFromBackend(roomId);

        // if (elements) {
        //   console.log("getSceneVersion(elements)", getSceneVersion(elements));
        //   this.setLastBroadcastedOrReceivedSceneVersion(
        //     getSceneVersion(elements),
        //   );

        let elements: OrderedExcalidrawElement[] = [];
        // const currtab = Tabs.get(currentTabId[0].tabId);
        // if (currtab) {
        //   elements = currtab.pages[currtab?.page ?? 0].points;
        // }

        return {
          elements,
          scrollToContent: true,
        };

        // }
      } catch (error: any) {
        // log the error and move on. other peers will sync us the scene.
        console.error(error);
      } finally {
        // this.portal.socketInitialized = true;
      }
    }
    return null;
  };

  private _reconcileElements = (
    remoteElements: readonly ExcalidrawElement[],
  ): ReconciledExcalidrawElement[] => {
    const localElements = this.getSceneElementsIncludingDeleted();
    const appState = this.excalidrawAPI.getAppState();
    const restoredRemoteElements = restoreElements(remoteElements, null);
    const reconciledElements = reconcileElements(
      localElements,
      restoredRemoteElements as RemoteExcalidrawElement[],
      appState,
    );

    // Avoid broadcasting to the rest of the collaborators the scene
    // we just received!
    // Note: this needs to be set before updating the scene as it
    // synchronously calls render.
    this.setLastBroadcastedOrReceivedSceneVersion(
      getSceneVersion(reconciledElements),
    );

    return reconciledElements;
  };

  private loadImageFiles = throttle(async () => {
    const { loadedFiles, erroredFiles } = await this.fetchImageFilesFromStorage(
      {
        elements: this.excalidrawAPI.getSceneElementsIncludingDeleted(),
      },
    );

    this.excalidrawAPI.addFiles(loadedFiles);

    updateStaleImageStatuses({
      excalidrawAPI: this.excalidrawAPI,
      erroredFiles,
      elements: this.excalidrawAPI.getSceneElementsIncludingDeleted(),
    });
  }, LOAD_IMAGES_TIMEOUT);

  private handleRemoteSceneUpdate = (
    elements: ReconciledExcalidrawElement[],
  ) => {


    // console.warn("point added..",this.excalidrawAPI)

    this.excalidrawAPI.updateScene({
      elements,
      storeAction: StoreAction.UPDATE,
    });

    this.loadImageFiles();
  };

  // private onPointerMove = () => {
  //   if (this.idleTimeoutId) {
  //     window.clearTimeout(this.idleTimeoutId);
  //     this.idleTimeoutId = null;
  //   }

  //   this.idleTimeoutId = window.setTimeout(this.reportIdle, IDLE_THRESHOLD);

  //   if (!this.activeIntervalId) {
  //     this.activeIntervalId = window.setInterval(
  //       this.reportActive,
  //       ACTIVE_THRESHOLD,
  //     );
  //   }
  // };

  // private onVisibilityChange = () => {
  //   if (document.hidden) {
  //     if (this.idleTimeoutId) {
  //       window.clearTimeout(this.idleTimeoutId);
  //       this.idleTimeoutId = null;
  //     }
  //     if (this.activeIntervalId) {
  //       window.clearInterval(this.activeIntervalId);
  //       this.activeIntervalId = null;
  //     }
  //     this.onIdleStateChange(UserIdleState.AWAY);
  //   } else {
  //     this.idleTimeoutId = window.setTimeout(this.reportIdle, IDLE_THRESHOLD);
  //     this.activeIntervalId = window.setInterval(
  //       this.reportActive,
  //       ACTIVE_THRESHOLD,
  //     );
  //     this.onIdleStateChange(UserIdleState.ACTIVE);
  //   }
  // };

  // private reportIdle = () => {
  //   this.onIdleStateChange(UserIdleState.IDLE);
  //   if (this.activeIntervalId) {
  //     window.clearInterval(this.activeIntervalId);
  //     this.activeIntervalId = null;
  //   }
  // };

  // private reportActive = () => {
  //   this.onIdleStateChange(UserIdleState.ACTIVE);
  // };

  // private initializeIdleDetector = () => {
  //   document.addEventListener(EVENT.POINTER_MOVE, this.onPointerMove);
  //   document.addEventListener(EVENT.VISIBILITY_CHANGE, this.onVisibilityChange);
  // };

  // setCollaborators(sockets: SocketId[]) {
  //   const collaborators: InstanceType<typeof Collab>["collaborators"] =
  //     new Map();
  //   for (const socketId of sockets) {
  //     collaborators.set(
  //       socketId,
  //       Object.assign({}, this.collaborators.get(socketId), {
  //         isCurrentUser: socketId === this.portal.socket?.id,
  //       }),
  //     );
  //   }
  //   this.collaborators = collaborators;
  //   this.excalidrawAPI.updateScene({ collaborators });
  // }

  updateCollaborator = (userId: SocketId, updates: Partial<Collaborator>) => {
    const collaborators = new Map(this.collaborators);

    const user: Mutable<Collaborator> = Object.assign(
      {},
      collaborators.get(userId),
      updates,
      {
        isCurrentUser: userId === this.userId,
      },
    );
    collaborators.set(userId as SocketId, user);

    console.log("usersdfhsdfs dkjsdfg sd", user);
    this.collaborators = collaborators;

    this.excalidrawAPI.updateScene({
      collaborators,
    });
  };

  public setLastBroadcastedOrReceivedSceneVersion = (version: number) => {
    console.log("set hone aaya hai ye version:::",version);
    this.lastBroadcastedOrReceivedSceneVersion = version;
  };

  public getLastBroadcastedOrReceivedSceneVersion = () => {
    return this.lastBroadcastedOrReceivedSceneVersion;
  };

  public getSceneElementsIncludingDeleted = () => {
    return this.excalidrawAPI.getSceneElementsIncludingDeleted();
  };

  onPointerUpdate = throttle(
    (payload: {
      pointer: { x: number; y: number; tool: "pointer" | "laser" };
      button: "down" | "up";
      pointersMap: Gesture["pointers"];
    }) => {
      // console.log("onPointer updated happening..", payload);

      if (payload.pointersMap.size < 2) {
        const msg: Collaborator = {
          pointer: payload.pointer,
          button: payload.button,
          selectedElementIds:
            this.excalidrawAPI.getAppState().selectedElementIds ?? null,
          id: this.userId!,
          username: this.userId,
        };

        // this.broadcastMouseLocation(msg, "");
      }
    },
    CURSOR_SYNC_TIMEOUT,
  );

  broadcastMouseLocation = (element: Collaborator, boardId: string) => {
    const finalMsg = JSON.stringify(element);
    if (typeof finalMsg === "undefined") {
      return;
    }

    const boordData = {
      id: boardId,
      mousePointerLocation: finalMsg,
    };

    const msgReq: MqttMessage = {
      data: JSON.stringify(boordData),
      userId: this.userId!,
      roomId: this.roomId!,
      type: MqttMessageType.POINTER_UPDATE,
      time: Date.now(),
    };
    // publishMessage(getWhiteboardTopic(this.roomId!), JSON.stringify(msgReq));
    // console.log("jaa rha pointer update...");
    this.client.publish(
      MqttService.getWhiteboardTopic(this.roomId!),
      JSON.stringify(msgReq),
    );
  };

  relayVisibleSceneBounds = throttleRAF((props?: { force: boolean }) => {
    const appState = this.excalidrawAPI.getAppState();

    // if (appState.followedBy.size > 0 || props?.force) {
    this.broadcastVisibleSceneBounds(getVisibleSceneBounds(appState), "");
    // }
  });

  // onIdleStateChange = (userState: UserIdleState) => {
  //   this.portal.broadcastIdleChange(userState);
  // };

  broadcastVisibleSceneBounds = (sceneBounds: SceneBounds, boardId: string) => {
    const finalMsg = {
      id: boardId,
      sceneBounds: JSON.stringify(sceneBounds),
    };
    const msgReq: MqttMessage = {
      data: JSON.stringify(finalMsg),
      userId: this.userId!,
      roomId: this.roomId!,
      type: MqttMessageType.USER_VISIBLE_SCENE_BOUNDS,
      time: Date.now(),
    };

    this.client.publish(
      MqttService.getWhiteboardTopic(this.roomId!),
      JSON.stringify(msgReq),
    );
  };

  broadcastElements = (
    tabId: string,
    elements: readonly OrderedExcalidrawElement[],
  ) => {
    if (
      getSceneVersion(elements) >
      this.getLastBroadcastedOrReceivedSceneVersion()
    ) {
      console.log("broad casting msg",getSceneVersion(elements)," last: ",this.getLastBroadcastedOrReceivedSceneVersion())

      this.broadcastScene(tabId, elements, false);
      this.lastBroadcastedOrReceivedSceneVersion = getSceneVersion(elements);
      this.queueBroadcastAllElements(tabId);
    }
  };

  syncElements = (
    tabId: string,
    elements: readonly OrderedExcalidrawElement[],
  ) => {
    console.log("syncing elements..");
    this.broadcastElements(tabId, elements);
    // this.queueSaveToFirebase();
  };

  queueBroadcastAllElements = throttle((tabId: string) => {
    // this.portal.broadcastScene(
    //   WS_SUBTYPES.UPDATE,
    //   this.excalidrawAPI.getSceneElementsIncludingDeleted(),
    //   true,
    // );
    // console.log("broadcasting all...");
    this.broadcastScene(
      tabId,
      this.excalidrawAPI.getSceneElementsIncludingDeleted(),
      true,
      "",
    );
    const currentVersion = this.getLastBroadcastedOrReceivedSceneVersion();
    const newVersion = Math.max(
      currentVersion,
      getSceneVersion(this.excalidrawAPI.getSceneElementsIncludingDeleted()),
    );
    this.setLastBroadcastedOrReceivedSceneVersion(newVersion);
  }, SYNC_FULL_SCENE_INTERVAL_MS);

  queueFileUpload = throttle(async () => {
    try {
      // console.log("queue file upload started...");
      await this.fileManager.saveFiles({
        elements: this.excalidrawAPI.getSceneElementsIncludingDeleted(),
        files: this.excalidrawAPI.getFiles(),
      });
    } catch (error: any) {
      if (error.name !== "AbortError") {
        // this.collab.excalidrawAPI.updateScene({
        //   appState: {
        //     errorMessage: error.message,
        //   },
        // });
      }
    }

    let isChanged = false;
    const newElements = this.excalidrawAPI
      .getSceneElementsIncludingDeleted()
      .map((element) => {
        if (this.fileManager.shouldUpdateImageElementStatus(element)) {
          isChanged = true;
          // this will signal collaborators to pull image data from server
          // (using mutation instead of newElementWith otherwise it'd break
          // in-progress dragging)
          return newElementWith(element, { status: "saved" });
        }
        return element;
      });

    if (isChanged) {
      this.excalidrawAPI.updateScene({
        elements: newElements,
        storeAction: StoreAction.UPDATE,
      });
    }
  }, FILE_UPLOAD_TIMEOUT);

  broadcastScene = async (
    tabId: string,
    elements: readonly OrderedExcalidrawElement[],
    syncAll: boolean,
    boardId?: string,
  ) => {
    const syncableElements = elements.reduce((acc, element) => {
      if (
        (syncAll ||
          !this.broadcastedElementVersions.has(element.id) ||
          element.version > this.broadcastedElementVersions.get(element.id)!) &&
        isSyncableElement(element)
      ) {
        acc.push(element);
      }
      return acc;
    }, [] as SyncableExcalidrawElement[]);

    for (const syncableElement of syncableElements) {
      this.broadcastedElementVersions.set(
        syncableElement.id,
        syncableElement.version,
      );
    }

    // console.log("publishing msg syncableElements...",syncableElements)
    if (syncableElements.length == 0) {
      return;
    }

    this.queueFileUpload();

    console.log("adding tab points for tabId: ", tabId);
    const finalMsg: MqttWhiteboardPointsPayload = {
      points: syncableElements,
      tabId: tabId,
      page: 0,
    };
    const msgReq: MqttMessage = {
      data: JSON.stringify(finalMsg),
      userId: this.userId!,
      roomId: this.roomId!,
      type: MqttMessageType.SCENE_UPDATE,
      time: Date.now(),
    };

    this.client.publish(
      MqttService.getWhiteboardTopic(this.roomId!),
      JSON.stringify(msgReq),
    );

    // console.log("publishing message on topic..",MqttService.getWhiteboardTopic(this.roomId!), "| message:",msgReq)

    // publishMessage(getWhiteboardTopic(this.roomId!), JSON.stringify(msgReq));
  };

  // switchTab = (tab: TabMetaData) => {
  //   const finalMsg: MqttWhiteboardTabPayload = {
  //     tabId: tab.tabId,
  //     type: tab.type
  //   };
  //   const msgReq: MqttMessage = {
  //     data: JSON.stringify(finalMsg),
  //     userId: this.userId!,
  //     roomId: this.roomId!,
  //     type: MqttMessageType.SWITCH_TAB,
  //     time: Date.now(),
  //   };

  //   this.client.publish(
  //     MqttService.getWhiteboardTopic(this.roomId!),
  //     JSON.stringify(msgReq),
  //   );
  // };

  // deleteTab = (tab : TabMetaData) => {
  //   const finalMsg: MqttWhiteboardTabPayload = {
  //     tabId: tab.tabId,
  //     type:tab.type
  //   };

  //   const msgReq: MqttMessage = {
  //     data: JSON.stringify(finalMsg),
  //     userId: this.userId!,
  //     roomId: this.roomId!,
  //     type: MqttMessageType.DELETE_TAB,
  //     time: Date.now(),
  //   };

  //   this.client.publish(
  //     MqttService.getWhiteboardTopic(this.roomId!),
  //     JSON.stringify(msgReq),
  //   );
  // }

  // createTab = (tab: TabMetaData) => {
  //   const finalMsg: MqttWhiteboardTabPayload = {
  //     tabId: tab.tabId,
  //     page: 0,
  //     title:tab.title,
  //     type:tab.type
  //   };

  //   const msgReq: MqttMessage = {
  //     data: JSON.stringify(finalMsg),
  //     userId: this.userId!,
  //     roomId: this.roomId!,
  //     type: MqttMessageType.CREATE_TAB,
  //     time: Date.now(),
  //   };

  //   console.log("published create tab....");

  //   this.client.publish(
  //     MqttService.getWhiteboardTopic(this.roomId!),
  //     JSON.stringify(msgReq),
  //   );
  // };

  // queueSaveToFirebase = throttle(
  //   () => {
  //     if (this.portal.socketInitialized) {
  //       this.saveCollabRoomToFirebase(
  //         getSyncableElements(
  //           this.excalidrawAPI.getSceneElementsIncludingDeleted(),
  //         ),
  //       );
  //     }
  //   },
  //   SYNC_FULL_SCENE_INTERVAL_MS,
  //   { leading: false },
  // );

  // setUsername = (username: string) => {
  //   this.setState({ username });
  //   saveUsernameToLocalStorage(username);
  // };

  // getUsername = () => this.state.username;

  // setActiveRoomLink = (activeRoomLink: string | null) => {
  //   this.setState({ activeRoomLink });
  //   appJotaiStore.set(activeRoomLinkAtom, activeRoomLink);
  // };

  // getActiveRoomLink = () => this.state.activeRoomLink;

  // setErrorIndicator = (errorMessage: string | null) => {
  //   appJotaiStore.set(collabErrorIndicatorAtom, {
  //     message: errorMessage,
  //     nonce: Date.now(),
  //   });
  // };

  // resetErrorIndicator = (resetDialogNotifiedErrors = false) => {
  //   appJotaiStore.set(collabErrorIndicatorAtom, { message: null, nonce: 0 });
  //   if (resetDialogNotifiedErrors) {
  //     this.setState({
  //       dialogNotifiedErrors: {},
  //     });
  //   }
  // };

  // setErrorDialog = (errorMessage: string | null) => {
  //   this.setState({
  //     errorMessage,
  //   });
  // };

  // render() {
  //   const { errorMessage } = this.state;

  //   return (
  //     <>
  //       {errorMessage != null && (
  //         <ErrorDialog onClose={() => this.setErrorDialog(null)}>
  //           {errorMessage}
  //         </ErrorDialog>
  //       )}
  //     </>
  //   );
  // }
}

// if (import.meta.env.MODE === ENV.TEST || import.meta.env.DEV) {
//   window.collab = window.collab || ({} as Window["collab"]);
// }

export default Collab;

let collab: Collab | null = null;

export const createCollab = (props: CollabProps): Collab => {
  // if (!collab) {
    // collab = new Collab(props);
  // }
  return new Collab(props);
};

export const getCollab = (): Collab => {
  if(!collab) {
    throw new Error("Collab is not created yet.");
  }

  return collab;
}