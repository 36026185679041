import React, { useState } from "react";
import { useAtomValue } from "jotai";
import "./TabHeader.scss";
import { Button, IconButton, Menu, MenuItem } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import NoteAltRoundedIcon from "@mui/icons-material/NoteAltRounded";
import MenuIcon from "@mui/icons-material/Menu"; // Menu Icon for the dropdown
import { currentTabAtom, tabListAtom } from "../../../store/tabList";
import { TabMetaData } from "../../../types/tabs";
import CloseIcon from "@mui/icons-material/Close"; // Close Icon for removing tabs
import { participantListAtom } from "../../../store/participantList";
import { currentSessionAtom } from "../../../store/session";

interface TabHeaderProps {
  onTabCreate: () => void;
  onTabSelect: (tab: TabMetaData) => void;
  onTabClose: (tab: TabMetaData) => void;
}

export const TabHeader = ({
  onTabCreate,
  onTabSelect,
  onTabClose,
}: TabHeaderProps) => {
  const currentTab = useAtomValue(currentTabAtom);
  const currentSession = useAtomValue(currentSessionAtom);
  const tabs = useAtomValue(tabListAtom);
  const currentUser = useAtomValue(participantListAtom).get(
    currentSession?.user.userId!,
  );

  // State for managing the dropdown menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleTabSelectFromMenu = (tab: TabMetaData) => {
    onTabSelect(tab);
    handleMenuClose();
  };

  return (
    <header className="header">
      <div className="header-container">
        <IconButton
          disabled={!currentUser?.controls?.write}
          onClick={onTabCreate}
        >
          <AddIcon />
        </IconButton>

        <div className="tabs-container">
          {tabs.map((tab: TabMetaData) => (
            <div key={tab.tabId} className="tab-wrapper">
              <Button
                disabled={!currentUser?.controls?.write}
                color="primary"
                startIcon={<NoteAltRoundedIcon />}
                disableElevation
                key={tab.tabId}
                onClick={() => onTabSelect(tab)}
                className={`board-button ${
                  tab.tabId === currentTab?.tabId ? "active" : ""
                }`}
              >
                {tab.title}
              </Button>
              {currentUser?.controls?.write ? (
                <IconButton
                  size="small"
                  onClick={() => onTabClose(tab)}
                  className="close-button"
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              ) : null}
            </div>
          ))}
        </div>

        {currentUser?.controls?.write ? (
          <IconButton className="menu-button" onClick={handleMenuClick}>
            <MenuIcon />
          </IconButton>
        ) : null}

        <Menu
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          {tabs.map((tab: TabMetaData) => (
            <MenuItem
              key={tab.tabId}
              selected={tab.tabId === currentTab?.tabId}
              onClick={() => handleTabSelectFromMenu(tab)}
            >
              {tab.title}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </header>
  );
};
