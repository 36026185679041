import React, { useState, useEffect } from "react";
import { FormControl, MenuItem, Select, Typography, Box } from "@mui/material";
import { getMediaServerConn } from "../../../utils/livekitClient";

// Mock function to simulate network quality metrics (replace with actual metrics retrieval)
const getNetworkQuality = () => {
  return {
    speed: "50 Mbps",
    bandwidth: "20 Mbps",
    jitter: "5 ms",
    latency: "30 ms",
  };
};

export const GeneralSettings = () => {
  // State for selected language
  const [selectedLanguage, setSelectedLanguage] = useState<string>("en");

  // State for network quality
  const [networkQuality, setNetworkQuality] = useState({
    speed: "",
    bandwidth: "",
    jitter: "",
    latency: "",
  });

  const conn = getMediaServerConn();

  useEffect(()=>{

    conn.room.localParticipant.trackPublications.forEach(async (track)=>{
      const tt = await track.track?.getRTCStatsReport();
      console.log("stats::",tt)
    })

  },[conn])

  // Simulating network quality metrics retrieval
  useEffect(() => {
    const interval = setInterval(() => {
      const quality = getNetworkQuality(); // Fetch network metrics
      setNetworkQuality(quality);
    }, 5000); // Update every 5 seconds (adjust as needed)

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, []);

  return (
    <Box >
      {/* Language Selector - Title and Select Side by Side */}
      <Box
        mb={4}
        display="flex"
        flexDirection="column"
        gap={1} // Space between the title and the select
      >
        <Typography variant="body1" >
          Language
        </Typography>
        <FormControl sx={{ minWidth: 80 }}> {/* Reduced width */}
          <Select
            id="language-select"
            value={selectedLanguage}
            onChange={(e) => setSelectedLanguage(e.target.value)}
            size="small"
            sx={{ fontSize: 14 }}
          >
            <MenuItem value="en">English</MenuItem>
            <MenuItem value="es">Spanish</MenuItem>
            <MenuItem value="fr">French</MenuItem>
            <MenuItem value="de">German</MenuItem>
            <MenuItem value="zh">Chinese</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* Network Metrics */}
      <Box>
        <Typography variant="h6" sx={{ fontWeight: 500, mb: 1 }}>
          Network Quality
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography variant="body1" sx={{ fontSize: 14 }}>
            <strong>Speed:</strong> {networkQuality.speed}
          </Typography>
          <Typography variant="body1" sx={{ fontSize: 14 }}>
            <strong>Bandwidth:</strong> {networkQuality.bandwidth}
          </Typography>
          <Typography variant="body1" sx={{ fontSize: 14 }}>
            <strong>Jitter:</strong> {networkQuality.jitter}
          </Typography>
          <Typography variant="body1" sx={{ fontSize: 14 }}>
            <strong>Latency:</strong> {networkQuality.latency}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default GeneralSettings;
