import { useAtom } from "jotai";
import { isSettingsModalOpen } from "../../store/session";
import { useState } from "react";
import {
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import React from "react";
import { Close, Settings, Mic, Videocam } from "@mui/icons-material";

import { GeneralSettings } from "./_components/GeneralSettings";
import AudioSettings from "./_components/AudioSettings";
import VideoSettings from "./_components/VideoSettings";

export const SettingsModal = () => {
  const [isModalOpen, setIsModalOpen] = useAtom(isSettingsModalOpen);
  const [selectedSetting, setSelectedSetting] = useState("general");

  const handleSelectSettings = (settings: string) => {
    setSelectedSetting(settings);
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      fullWidth
      maxWidth="md"
    >
      <DialogContent style={{ padding: 0, position: "relative" }}>
        {/* Title and Close Button */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "20px",
            borderBottom: "1px solid #ddd",
          }}
        >
          <Typography variant="h6">Settings</Typography>
          <IconButton onClick={() => setIsModalOpen(false)}>
            <Close />
          </IconButton>
        </Box>

        <Box sx={{ display: "flex", width: "100%" }}>
          {/* Left-Side Menu */}
          <Box
            sx={{
              width: {
                xs: "60px", // Smaller width on extra-small and small screens
                sm: "80px", // Slightly wider on small screens
                md: "250px", // Regular width on medium and larger screens
              },
              backgroundColor: "#f5f5f5",
              borderRight: "1px solid #ddd",
              padding: {
                xs: "10px", // Reduced padding on small screens
                sm: "10px", // Reduced padding for small screens
                md: "15px", // Slightly more padding for medium and larger screens
              },
            }}
          >
            <List>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => handleSelectSettings("general")}
                  style={{
                    backgroundColor:
                      selectedSetting === "general" ? "#1976d2" : "transparent",
                    color: selectedSetting === "general" ? "white" : "inherit",
                    borderRadius: "8px",
                    marginBottom: "10px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Settings sx={{ marginRight: { xs: 0, sm: "10px" } }} />
                  <ListItemText
                    primary="General"
                    sx={{ display: { xs: "none", md: "block" } }} // Hide text on small screens
                  />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => handleSelectSettings("audio")}
                  style={{
                    backgroundColor:
                      selectedSetting === "audio" ? "#1976d2" : "transparent",
                    color: selectedSetting === "audio" ? "white" : "inherit",
                    borderRadius: "8px",
                    marginBottom: "10px",
                    justifyContent: "center",
                  }}
                >
                  <Mic sx={{ marginRight: { xs: 0, sm: "10px" } }} />
                  <ListItemText
                    primary="Audio"
                    sx={{ display: { xs: "none", md: "block" } }} // Hide text on small screens
                  />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => handleSelectSettings("video")}
                  style={{
                    backgroundColor:
                      selectedSetting === "video" ? "#1976d2" : "transparent",
                    color: selectedSetting === "video" ? "white" : "inherit",
                    borderRadius: "8px",
                    marginBottom: "10px",
                    justifyContent: "center",
                  }}
                >
                  <Videocam sx={{ marginRight: { xs: 0, sm: "10px" } }} />
                  <ListItemText
                    primary="Video"
                    sx={{ display: { xs: "none", md: "block" } }} // Hide text on small screens
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>

          {/* Right-Side Content */}
          <Box
            sx={{
              flex: 1, // Take up remaining space
              backgroundColor: "#fff",
              padding: "20px",
              minHeight: "400px", // Set minimum height
            }}
          >
            {/* Render the selected setting content */}
            {selectedSetting === "general" && <GeneralSettings />}

            {selectedSetting === "audio" && <AudioSettings />}

            {selectedSetting === "video" && (
             <VideoSettings />
            )}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
