import { useAtom, useAtomValue } from "jotai";
import {
  currentSessionAtom,
  isExternalPageModalOpen,
  isExternalVideoModalOpen,
} from "../../../store/session";
import { useState } from "react";
import { isEmpty } from "lodash";

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid2,
  IconButton,
  TextField,
} from "@mui/material";
import React from "react";
import { Close } from "@mui/icons-material";
import ReactPlayer from "react-player/lazy";
import { syncData } from "../../../utils/roomClient";
import { TabMetaData } from "../../../types/tabs";

const ExternalPageLinkModal = () => {
  const [isModalOpen, setIsModalOpen] = useAtom(isExternalPageModalOpen);

  const [videoLink, setVideoLink] = useState("");

  const currentSession = useAtomValue(currentSessionAtom);

  const handleClose = () => {
    console.log("close hoa");
    setIsModalOpen(false);
  };

  return (
    <Dialog open={isModalOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Display External Webpage
        <IconButton
          style={{ position: "absolute", right: 8, top: 8 }}
          onClick={handleClose}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div style={{ padding: "5px" }}>
          <TextField
            label="Paste webpage URL"
            variant="outlined"
            fullWidth
            value={videoLink}
            onChange={(e) => setVideoLink(e.target.value)}
            style={{ marginBottom: "15px" }}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => {
              if (isEmpty(videoLink)) {
                console.log("no url");
                return;
              }

             

              console.log("created tab.... ");

              const tab: TabMetaData = {
                tabId: currentSession?.user.userId!,
                type: "webpage",
                title: "Webpage",
                url: (videoLink),
              };
              syncData.createTab(
                currentSession?.room.roomId!,
                currentSession?.user.userId!,
                tab,
              );

              syncData.switchTab(
                currentSession?.room.roomId!,
                currentSession?.user.userId!,
                tab,
              );

              handleClose();
            }}
          >
            Play
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export { ExternalPageLinkModal };
