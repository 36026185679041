import { atom } from "jotai";
import { Participant } from "../types/livekit";
import { appJotaiStore } from "../app-jotai";

export interface LayoutSettings {
  whiteboard: number;
  video: number;
  chat: number;
}

export const sessionLayoutAtom = atom<LayoutSettings>({
  whiteboard: 80,
  video: 20,
  chat: 0,
});

interface RoomDetails {
  title: string;
  userId: string;
  createdAt: number;
  roomId: string;
}

export interface Session {
  user: Participant;
  room: RoomDetails;
  token: string;
  livekitUrl: string;
  mqttUrl: string;

}

export interface ExternalMediPlayer {
  action: string;
  seekTo: number;
}

export interface EditorState {
  action: string;
  data: string;
  userId: string;
}

export const externalMediaPlayerAtom = atom<ExternalMediPlayer>({
  action: "",
  seekTo: -1,
});

export const editorStateAtom = atom<EditorState>({
  action: "",
  data: "",
  userId: "",
});

export interface DeviceManager {
  selectedAudioInput: string;
  selectedVideoInput: string;
  selectedAudioOutput: string;
  audioDevices:MediaDeviceInfo[],
  videoDevices:MediaDeviceInfo[],
  audioOutputDevices:MediaDeviceInfo[],
  resolution:string
}

export const currentSessionAtom = atom<Session | null>(null);
export const isSettingsModalOpen = atom(false);
export const isExternalVideoModalOpen = atom(false);
export const isExternalPageModalOpen = atom(false);
export const openDeviceListAtom = atom<"audio" | "video" | null>(null);
export const isAppsVisible = atom(false);
export const isExitModalOpen = atom(false)
export const isChatVisibleAtom = atom(false);

export const deviceManagerAtom = atom<DeviceManager>({
  selectedAudioInput:"",
  selectedVideoInput:"",
  selectedAudioOutput:"",
  audioDevices:[],
  videoDevices:[],
  audioOutputDevices:[],
  resolution:'h360'
});

// Function to add audio devices
export const updateAudioDevices = (devices: MediaDeviceInfo[]) => {
  appJotaiStore.set(deviceManagerAtom, (prev)=>{
    return {...prev, audioDevices:devices}
  });
};

export const updateVideoResultion = (resolution:string) => {
  appJotaiStore.set(deviceManagerAtom, (prev)=>{
    return {...prev, resolution:resolution}
  });
}
// Function to add audio devices
export const updateAudioOutputDevices = (devices: MediaDeviceInfo[]) => {
  appJotaiStore.set(deviceManagerAtom, (prev)=>{
    return {...prev, audioOutputDevices:devices}
  });
};

// Function to add video devices
export const updateVideoDevices = (devices: MediaDeviceInfo[]) => {
  appJotaiStore.set(deviceManagerAtom, (prev)=>{
    return {...prev, videoDevices:devices}
  });
};

// Function to select an audio output device
export const updateSelectedAudioInputDevice = (deviceId: string) => {
  appJotaiStore.set(deviceManagerAtom, (prev)=>{
    return {...prev, selectedAudioInput:deviceId}
  });
};

// Function to select an audio output device
export const updateSelectedAudioOutputDevice = (deviceId: string) => {
  appJotaiStore.set(deviceManagerAtom, (prev)=>{
    return {...prev, selectedAudioOutput:deviceId}
  });
};

// Function to select a video input device
export const updateSelectedVideoInputDevice = (deviceId: string) => {
  appJotaiStore.set(deviceManagerAtom, (prev)=>{
    return {...prev, selectedVideoInput:deviceId}
  });
};