import React, { useEffect, useRef, useState } from "react";
import ExcalidrawApp from "./components/whiteboard";
import { useLocation } from "react-router-dom";
import { atom, useAtom, useAtomValue } from "jotai";
import { appJotaiStore } from "./app-jotai";
import { fetchLivekitToken, fetchParticipants, fetchSessionState } from "./api";
import MqttService, {
  createMqttConnection,
  getMqttConn,
} from "./utils/mqttClient";
import { Allotment, AllotmentHandle } from "allotment";
import AudioVideoLayout from "./components/audio-video";
import "./App.scss";
import "allotment/dist/style.css";
import { createLivekitConnection } from "./utils/livekitClient";
import { participantHandler } from "./utils/participantHandler";
import { ParticipantRole, type Participant } from "./types/livekit";
import { ConnectionQuality } from "livekit-client";
import type { MqttMessage } from "./types/whiteboard";
import { MqttMessageType } from "./types/whiteboard";
import ControlsLayout from "./components/bottom-bar/controls-layout";
import { syncCallback, syncData } from "./utils/roomClient";
import {
  addParticipant,
  addParticipants,
  participantListAtom,
  participantLoadingAtom,
} from "./store/participantList";
import { ParticipantComponent } from "./components/participants";
import {
  currentSessionAtom,
  LayoutSettings,
  Session,
  sessionLayoutAtom,
} from "./store/session";
import DeviceTestPage from "./components/device-test";
import { SettingsModal } from "./components/settings";
import { AppsList } from "./components/apps";
import { ExternalVideoLinkModal } from "./components/external-media/_components/modal";
import { ExternalPageLinkModal } from "./components/webpage/_components/modal";
import { getWebcamResolution } from "./helpers/utils";
import { ChatComponent } from "./components/chats";
import { ExitComponent } from "./components/exit";

const App = () => {
  const session = useAtomValue(currentSessionAtom);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const mqttInitialized = useRef<boolean>(false);
  const [layout, setLayout] = useAtom(sessionLayoutAtom);
  // const location = useLocation();
  const ref = React.useRef<AllotmentHandle | null>(null);

  useEffect(() => {
    console.log("layout state changed...", layout);
    if (ref.current) {
      console.log("reff", ref.current);
      ref.current.resize([layout.whiteboard, layout.video]);
      // ref.current.reset();
    }
  }, [layout]);

  useEffect(() => {
    if (session && !mqttInitialized.current) {
      setLoading(true);
      fetchUserAndRoomDetails(session)
        .then(() => {
          mqttInitialized.current = true; // Prevent re-initialization
          setLoading(false);
        })
        .catch((erro: any) => {
          setLoading(false);
          setErrorMessage(erro.message);
        });
    }
  }, [session]);

  if (!session) {
    return <DeviceTestPage />;
  }

  if (errorMessage) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-center">
          <p className="text-xl text-red-500">{errorMessage}</p>
        </div>
      </div>
    );
  }

  if (loading) {
    return <div>Loading, please wait...</div>;
  }

  return (
    <div className="main-container">
      <div className="main-content">
        <Allotment
          ref={ref}
          defaultSizes={[layout.whiteboard, layout.video]}
          separator={true}
          // onVisibleChange={(index, visible) => {
          //   if (index == 0) {
          //     setLayout({ ...layout, whiteboard: visible });
          //   }
          //   if (index == 1) {
          //     setLayout({ ...layout, video: visible });
          //   }
          //   // console.log(
          //   //   "visiblity changed of.. index: ",
          //   //   index,
          //   //   " visible: ",
          //   //   visible,
          //   // );
          // }}
          // onChange={(sizes) => {
          //   console.log("on change end ..", sizes);
          // }}
          onDragEnd={(sizes) => {
            console.log("on drag end: ", sizes);
            const newLayout: LayoutSettings = {
              whiteboard: sizes[0],
              video: sizes[1],
              chat: 0,
            };
            setLayout(newLayout);

            syncData.LayoutChange(
              session.room.roomId,
              session.user.userId,
              newLayout,
            );
          }}
        >
          <Allotment.Pane snap={true} visible={layout.whiteboard > 0}>
            <ExcalidrawApp />
          </Allotment.Pane>
          <Allotment.Pane snap={true} visible={layout.video > 0}>
            <AudioVideoLayout />
          </Allotment.Pane>
        </Allotment>
      </div>

      <ControlsLayout />
      <ParticipantComponent />
      <ChatComponent />
      <AppsList />
      <SettingsModal />
      <ExternalVideoLinkModal />
      <ExternalPageLinkModal />
      <ExitComponent />
    </div>
  );
};

export default App;

const fetchUserAndRoomDetails = async (session: Session) => {
  try {
    // connecting to mqtt;
    const mqttConnection = createMqttConnection(
      `${session.room.roomId}+${session.user.userId}`,
      session.mqttUrl,
    );

    await mqttConnection.connect(
      {},
      () => {
        console.log("Connected to MQTT, executing onConnect tasks...");

        setTimeout(() => {
          syncData.initParticipant(session.room.roomId, session.user);
        }, 1000);

        fetchParticipants(session.room.roomId).then((participants) => {
          console.log("got participants....", participants);
          if (participants) {
            addParticipants(participants);
          }
        });
      },
      (err) => {
        console.log("error while mqtt connection.");
      },
    );

    getMqttConn().subscribe(MqttService.getClassroomTopic(session.room.roomId));
    getMqttConn().subscribe(
      MqttService.getWhiteboardTopic(session.room.roomId),
    );

    getMqttConn().setMessageHandler((topic, payload) => {
      if (topic == MqttService.getClassroomTopic(session.room.roomId)) {
        syncCallback(payload);
      }
    });

    const livekitcon = createLivekitConnection(
      session.room.roomId,
      session.user.userId,
      session.livekitUrl,
      session.token,
    );
    await livekitcon.connect();


    console.log(
      "connecting livekit with... audio: ",
      session?.user?.audioEnabled && !session.user.isAudioMuted,
    );
    console.log(
      "connecting livekit with... video: ",
      session?.user?.videoEnabled && !session.user.isVideoMuted,
    );

    await livekitcon.publishTrack(
      session?.user?.audioEnabled && !session.user.isAudioMuted,
      session?.user?.videoEnabled && !session.user.isVideoMuted,

    );
  } catch (error) {}
};
