import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
// import { registerSW } from "virtual:pwa-register";

// import "../excalidraw-app/sentry";
import { SnackbarProvider } from 'notistack';

import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "jotai";
import { appJotaiStore } from "./app-jotai";
import DeviceTestPage from "./components/device-test";
// window.__EXCALIDRAW_SHA__ = import.meta.env.VITE_APP_GIT_SHA;
const rootElement = document.getElementById("root")!;
const root = createRoot(rootElement);
// registerSW();
root.render(
  // <StrictMode>
  <BrowserRouter>
    <Provider unstable_createStore={() => appJotaiStore}>
      <SnackbarProvider anchorOrigin={{vertical:"bottom", horizontal:"right"}} autoHideDuration={3000}>
        <App />
        {/* <DeviceTestPage /> */}
      </SnackbarProvider>
    </Provider>
  </BrowserRouter>,
  // </StrictMode>,
);
