import polyfill from "../../../packages/excalidraw/polyfill";
import { useCallback, useEffect, useRef, useState } from "react";
import { getDefaultAppState } from "../../../packages/excalidraw/appState";
import { ErrorDialog } from "../../../packages/excalidraw/components/ErrorDialog";
import { EVENT } from "../../../packages/excalidraw/constants";
import type {
  ExcalidrawImageElement,
  FileId,
  NonDeletedExcalidrawElement,
  OrderedExcalidrawElement,
} from "../../../packages/excalidraw/element/types";
import { useCallbackRefState } from "../../../packages/excalidraw/hooks/useCallbackRefState";
import { t } from "../../../packages/excalidraw/i18n";
import {
  Excalidraw,
  LiveCollaborationTrigger,
  TTDDialogTrigger,
  StoreAction,
  reconcileElements,
  Footer,
  getSceneVersion,
} from "../../../packages/excalidraw";

import { TabHeader } from "./_components/TabHeader";
import type {
  AppState,
  ExcalidrawImperativeAPI,
  BinaryFiles,
  ExcalidrawInitialDataState,
  UIAppState,
  BinaryFileData,
  DataURL,
} from "../../../packages/excalidraw/types";
import type { ResolvablePromise } from "../../../packages/excalidraw/utils";
import {
  getVersion,
  resolvablePromise,
} from "../../../packages/excalidraw/utils";

import type Collab from "./sync";
import { exportToBackend } from "./data";

import {
  restore,
  restoreAppState,
  restoreElements,
} from "../../../packages/excalidraw/data/restore";

import { updateStaleImageStatuses } from "./data/FileManager";

import {
  LibraryIndexedDBAdapter,
  LibraryLocalStorageMigrationAdapter,
  LocalData,
} from "./data/LocalData";
import {
  parseLibraryTokensFromUrl,
  useHandleLibrary,
} from "../../../packages/excalidraw/data/library";
import { AppMainMenu } from "./_components/AppMainMenu";
import { Provider, useAtom, useAtomValue } from "jotai";
import { appJotaiStore } from "./../../app-jotai";

import "./index.scss";
import type { ResolutionType } from "../../../packages/excalidraw/utility-types";

import type { RemoteExcalidrawElement } from "../../../packages/excalidraw/data/reconcile";

import { appThemeAtom, useHandleAppTheme } from "./../../useHandleAppTheme";
import { useAppLangCode } from "./../../app-language/language-state";

import { AIComponents } from "./_components/AI";
import React from "react";
import MqttService, { getMqttConn } from "../../utils/mqttClient";
import FooterUI from "./_components/FooterUI";
import { ImportedDataState } from "../../../packages/excalidraw/data/types";
import { loadFilesFromStorage, loadFromBackend } from "./data/storage";
import {
  addTab,
  createTabMetadata,
  currentTabAtom,
  removeTab,
  setCurrentTab,
  tabListAtom,
  Tabs,
  updateTab,
} from "../../store/tabList";
import { Page, Tab, TabMetaData } from "../../types/tabs";
import {
  MqttMessage,
  MqttMessageType,
  MqttWhiteboardTabPayload,
} from "../../types/whiteboard";
import { createCollab } from "./sync";
import {
  CurrentConnectionEvents,
  getMediaServerConn,
} from "../../utils/livekitClient";
import { syncData } from "../../utils/roomClient";
import {
  LocalTrackPublication,
  RemoteTrackPublication,
  Track,
} from "livekit-client";
import {
  currentSessionAtom,
  externalMediaPlayerAtom,
} from "../../store/session";
import { ExternalVideoPlayer } from "../external-media";
import { ExternalWebpage } from "../webpage";
import { QuillEditor } from "../editor";
import * as pdfjsLib from "pdfjs-dist";

// import workerUrl from "pdfjs-dist/build/pdf.worker.min.mjs";
import { randomId, randomInteger } from "../../../packages/excalidraw/random";
import { Radians } from "../../../packages/math";
import usePreviousTab from "./hooks/usePreviousTab";
// pdfjsLib.GlobalWorkerOptions.workerSrc = workerUrl;

polyfill();

// window.EXCALIDRAW_THROTTLE_RENDER = true;

const initializeScene = async (opts: {
  collabAPI: Collab | null;
  excalidrawAPI: ExcalidrawImperativeAPI;
  viewMode: boolean;
  tabId?: string;
  pageIndex?: number;
}): Promise<
  { scene: ExcalidrawInitialDataState | null } & (
    | { isExternalScene: true }
    | { isExternalScene: false }
  )
> => {
  const { excalidrawAPI, tabId } = opts;

  let scene:
    | (ImportedDataState & { elements: readonly OrderedExcalidrawElement[] })
    | null
    | undefined;
  if (tabId) {
    const currentTab = Tabs.get(tabId);

    if (currentTab && currentTab.pages) {
      const elements = currentTab.pages[opts.pageIndex ?? 0]?.points ?? [];
      if (elements) {
        console.log("elemetsn...", elements);
        console.log("scenever sion sfsd sd...", getSceneVersion(elements));

        console.log("opt.colalba hai ya ni..", opts.collabAPI);
        opts.collabAPI?.setLastBroadcastedOrReceivedSceneVersion(
          getSceneVersion(elements),
        );

        console.log(
          "set last broadcast scene version.... ",
          opts.collabAPI?.getLastBroadcastedOrReceivedSceneVersion(),
        );
      }
      scene = {
        elements: elements,
        appState: {
          viewModeEnabled: opts.viewMode,
        },
        files: {},
      };
    }
  } else {
    console.log("this is first time load from database...");
    // scene = await opts?.collabAPI?.startCollaboration(opts.roomId);
  }

  console.log(
    "app statge.... view mode.. ",
    excalidrawAPI.getAppState().viewModeEnabled,
  );

  return {
    scene: {
      ...scene,
      appState: {
        ...restoreAppState(
          {
            ...scene?.appState,
            theme: "light",
          },
          excalidrawAPI.getAppState(),
        ),

        isLoading: false,
      },
      elements: reconcileElements(
        scene?.elements || [],
        excalidrawAPI.getSceneElementsIncludingDeleted() as RemoteExcalidrawElement[],
        excalidrawAPI.getAppState(),
      ),
    },
    isExternalScene: true,
  };
};

const ExcalidrawWrapper = () => {
  const [errorMessage, setErrorMessage] = useState("");

  const [appTheme, setAppTheme] = useAtom(appThemeAtom);
  const [viewMode, setVideoMode] = useState(false);
  const { editorTheme } = useHandleAppTheme();

  const [langCode, setLangCode] = useAppLangCode();

  const initialStatePromiseRef = useRef<{
    promise: ResolvablePromise<ExcalidrawInitialDataState | null>;
  }>({ promise: null! });

  if (!initialStatePromiseRef.current.promise) {
    initialStatePromiseRef.current.promise =
      resolvablePromise<ExcalidrawInitialDataState | null>();
  }

  const [excalidrawAPI, excalidrawRefCallback] =
    useCallbackRefState<ExcalidrawImperativeAPI>();

  const [collabAPI, setCollabAPI] = useState<Collab | null>(null);


  const currentSession = useAtomValue(currentSessionAtom);
  const currentExternalMediaPlayer = useAtomValue(externalMediaPlayerAtom);
  const currentTab = useAtomValue(currentTabAtom);
  const previousTab = usePreviousTab(currentTab);


  

  const [screenVideoTrack, setScreenVideoTrack] = useState<
    LocalTrackPublication | RemoteTrackPublication | null
  >(null);

  const [externalVideoSrc, setexternalVideoSrc] = useState("");
  const [externalWebpage, setexternalWebpage] = useState("");

  const currentConnection = getMediaServerConn();
  useEffect(() => {
    console.log("calling tab data....");
    loadFromBackend(currentSession?.room.roomId!).then(
      ({ tabs, currentTabIndex }) => {
        console.log("tabs data...", tabs);
        // tabsData.forEach((tab) => {
        //   // Tabs.set(tab.tabId, tab);
        // });
        appJotaiStore.set(tabListAtom, tabs);

        console.log("tabs repo...", Tabs);

        const firstTab = tabs[currentTabIndex];
        setCurrentTab(firstTab);
      },
    );
  }, [currentSession?.room.roomId!]);

  useEffect(() => {
    console.log("vide mode hai ye...", currentSession?.user.controls?.write);

    setVideoMode(!currentSession?.user.controls?.write ?? false);
  }, [currentSession?.user.controls?.write]);

  useEffect(() => {
    currentConnection.on(
      CurrentConnectionEvents.ScreenSharedAdded,
      (userId) => {
        console.log(
          "screen share track detected. ",
          screenVideoTrack,
          "currentTab?.tabId == userId",
          currentTab?.tabId,
          " userID:",
          userId,
        );
        if (!screenVideoTrack && currentTab?.tabId == userId) {
          const tracks = currentConnection.getScreenTrack(userId);
          if (tracks) {
            for (let i = 0; i < tracks.length; i++) {
              if (tracks[i].source == Track.Source.ScreenShare) {
                console.log("screen track set kr diya event aane ke baad.");
                setScreenVideoTrack(tracks[i]);
              }
            }
          }
        }
      },
    );

    return () => {
      currentConnection.off(CurrentConnectionEvents.ScreenSharedAdded);
    };
  }, [currentConnection, currentTab]);

  useEffect(() => {
    if (!excalidrawAPI || !currentSession) {
      return;
    }

    console.log("excali draw api changed...",excalidrawAPI)

    const collab = createCollab({
      excalidrawAPI: excalidrawAPI,
      userId: currentSession?.user.userId!,
      roomId: currentSession?.room.roomId!,
    });

    const mqttConn = getMqttConn();

    mqttConn.setMessageHandler((topic, payload) => {
      if (
        topic == MqttService.getWhiteboardTopic(currentSession?.room.roomId!)
      ) {
        collab.onSubcribe(payload);
      }
    });
    setCollabAPI(collab);
  }, [excalidrawAPI, currentSession]);

  useEffect(() => {
    
    if (!currentSession) {
      return;
    }

    if(previousTab){
      savePreviousTab();
    }


    const loadImages = (
      data: ResolutionType<typeof initializeScene>,
      isInitialLoad = false,
    ) => {
      if (!data.scene || !excalidrawAPI) {
        return;
      }

      console.log("vide mode hai ye...", viewMode);

      if (data.scene.elements) {
        collabAPI
          ?.fetchImageFilesFromStorage({
            elements: data.scene.elements,
            forceFetchFiles: true,
          })
          .then(({ loadedFiles, erroredFiles }) => {
            excalidrawAPI.addFiles(loadedFiles);
            updateStaleImageStatuses({
              excalidrawAPI,
              erroredFiles,
              elements: excalidrawAPI.getSceneElementsIncludingDeleted(),
            });
          });
      }
    };

    const loadScene = async () => {
      if (!excalidrawAPI) return;

      excalidrawAPI.resetScene();

      initializeScene({
        collabAPI,
        excalidrawAPI,
        viewMode,
        tabId: currentTab?.tabId,
        pageIndex: currentTab?.pageIndex,
      }).then(async (data) => {
        loadImages(data, /* isInitialLoad */ true);
        console.log("initial data is showing...", data.scene);
        initialStatePromiseRef.current.promise.resolve(data.scene);
        excalidrawAPI.updateScene({
          elements: data.scene?.elements,
        });
      });
    };

    if (currentTab?.type == "normal") {
      if (excalidrawAPI && collabAPI) {
        loadScene();
      }
    } else if (currentTab?.type == "screen") {
      console.log(
        "screen board created... checking screen track",
        currentTab.tabId,
      );

      const tracks = currentConnection.getScreenTrack(currentTab.tabId);
      if (tracks) {
        for (let i = 0; i < tracks.length; i++) {
          if (tracks[i].source == Track.Source.ScreenShare) {
            setScreenVideoTrack(tracks[i]);
          }
        }
      } else {
        console.log("track abhi ni milaa hai...");
      }
    } else if (currentTab?.type == "external-video") {
      setexternalVideoSrc(Tabs.get(currentTab.tabId)?.url ?? "");
    } else if (currentTab?.type == "webpage") {
      setexternalWebpage(Tabs.get(currentTab.tabId)?.url ?? "");
    } else {
      console.log("koi unknown tab aayam,,,", currentTab);
    }

    const onHashChange = async (event: HashChangeEvent) => {
      if (!currentTab || !excalidrawAPI) {
        return;
      }
      event.preventDefault();
      const libraryUrlTokens = parseLibraryTokensFromUrl();
      if (!libraryUrlTokens) {
        excalidrawAPI.updateScene({ appState: { isLoading: true } });

        initializeScene({
          collabAPI,
          excalidrawAPI,
          viewMode,
          tabId: currentTab.tabId,
        }).then((data) => {
          loadImages(data);
          if (data.scene) {
            excalidrawAPI.updateScene({
              ...data.scene,
              ...restore(data.scene, null, null, { repairBindings: true }),
              storeAction: StoreAction.CAPTURE,
            });
          }
        });
      }
    };

    // window.addEventListener(EVENT.HASHCHANGE, onHashChange, false);

    // if (currentSession.presenter == "true") {
    //   excalidrawAPI.onScrollChange(() => {
    //     collabAPI?.relayVisibleSceneBounds();
    //   });
    // }

    return () => {
      window.removeEventListener(EVENT.HASHCHANGE, onHashChange, false);
    };
  }, [currentTab, excalidrawAPI, collabAPI]);

  useHandleLibrary({
    excalidrawAPI,
    adapter: LibraryIndexedDBAdapter,
    // TODO maybe remove this in several months (shipped: 24-03-11)
    migrationAdapter: LibraryLocalStorageMigrationAdapter,
  });

  const onChange = (
    elements: readonly OrderedExcalidrawElement[],
    appState: AppState,
    files: BinaryFiles,
  ) => {
    if (collabAPI) {
      collabAPI.syncElements(currentTab?.tabId!, elements);
    }
  };



  const onTabCreate = () => {
    const tabMeta = createTabMetadata();

    const page: Page = {
      points: [],
      index: 0,
    };
    // addTab(tabMeta);
    const tab: Tab = {
      type: tabMeta.type,
      pages: [page],
      // index: 0,
      tabId: tabMeta.tabId,
    };
    // Tabs.set(tab.tabId, tab);

    syncData.createTab(
      currentSession?.room.roomId!,
      currentSession?.user.userId!,
      tabMeta,
    );
    // collabAPI?.createTab(tabMeta);
    onTabSelect(tabMeta);
  };

  const savePreviousTab = () => {
    if (excalidrawAPI) {
      const previousTabId = previousTab?.tabId;
      if (previousTabId) {
        const localElements = excalidrawAPI.getSceneElements();
        const currentElements = restoreElements(localElements, null);
        const previousPages = Tabs.get(previousTabId)?.pages;
        if (previousPages && currentElements) {
          // console.log(
          //   "save ho gya current page//",
          //   previousPages.pageIndex,
          //   "currentPages[currentTab.pageIndex]",
          //   previousPages[currentTab.pageIndex ?? 0],
          // );
          previousPages[previousTab.pageIndex ?? 0].points = currentElements; // Save the current tab's elements
        }
      }


    }
  };

  const onPageChange = (currentPage: number) => {
    console.log("page change ho gya...", currentPage);
   // saveCurrentTab();
    updateTab(currentTab?.tabId!, { pageIndex: currentPage });
    // setCurrentTab(newTab);
  };

  const onTabClose = (tab: TabMetaData) => {
    removeTab(tab.tabId);

    syncData.deleteTab(
      currentSession?.room.roomId!,
      currentSession?.user.userId!,
      tab,
    );
    // collabAPI?.deleteTab(tab);
  };

  const onTabSelect = (tab: TabMetaData) => {
    //saveCurrentTab();
    console.log("tab change hua...", tab);
    // setCurrentTab(tab);
    syncData.switchTab(
      currentSession?.room.roomId!,
      currentSession?.user.userId!,
      tab,
    );
    // collabAPI?.switchTab(tab);
  };

  const onExportToBackend = async (
    exportedElements: readonly NonDeletedExcalidrawElement[],
    appState: Partial<AppState>,
    files: BinaryFiles,
  ) => {
    if (exportedElements.length === 0) {
      throw new Error(t("alerts.cannotExportEmptyCanvas"));
    }
    try {
      const { url, errorMessage } = await exportToBackend(
        exportedElements,
        {
          ...appState,
          viewBackgroundColor: appState.exportBackground
            ? appState.viewBackgroundColor
            : getDefaultAppState().viewBackgroundColor,
        },
        files,
      );

      if (errorMessage) {
        throw new Error(errorMessage);
      }

      // if (url) {
      //   setLatestShareableLink(url);
      // }
    } catch (error: any) {
      if (error.name !== "AbortError") {
        const { width, height } = appState;
        console.error(error, {
          width,
          height,
          devicePixelRatio: window.devicePixelRatio,
        });
        throw new Error(error.message);
      }
    }
  };

  const renderContent = () => {
    switch (currentTab?.type) {
      case "normal":
        console.log("rendering again excalid da... view mode..", viewMode);
        return (
          <Excalidraw
            excalidrawAPI={excalidrawRefCallback}
            onChange={onChange}
            initialData={initialStatePromiseRef.current.promise}
            isCollaborating={true}
            onPointerUpdate={collabAPI?.onPointerUpdate}
            viewModeEnabled={viewMode}
            UIOptions={{
              canvasActions: {
                toggleTheme: true,
              },
            }}
            langCode={langCode}
            detectScroll={false}
            handleKeyboardGlobally={true}
            autoFocus={true}
            theme={editorTheme}
          >
            <AppMainMenu
              theme={appTheme}
              setTheme={(theme) => setAppTheme(theme)}
            />
            {/* <AppWelcomeScreen /> */}

            <Footer>
              <FooterUI onPageChange={onPageChange} />
            </Footer>
            {excalidrawAPI && <AIComponents excalidrawAPI={excalidrawAPI} />}

            <TTDDialogTrigger />

            {errorMessage && (
              <ErrorDialog onClose={() => setErrorMessage("")}>
                {errorMessage}
              </ErrorDialog>
            )}
          </Excalidraw>
        );

      case "screen":
        if (screenVideoTrack) {
          return (
            <div className="screen-share-container">
              <video
                autoPlay
                playsInline
                ref={(videoElement) => {
                  if (videoElement) {
                    screenVideoTrack.videoTrack?.attach(videoElement);
                  }
                }}
              />
            </div>
          );
        }
        return (
          <div className="placeholder-container">
            <p className="text-center text-gray-500">Screen share</p>
          </div>
        );

      case "external-video":
        if (externalVideoSrc) {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                position: "relative",
                height: "100%",
                alignItems: "center",
              }}
            >
              <ExternalVideoPlayer
                action={currentExternalMediaPlayer.action}
                seekTo={currentExternalMediaPlayer.seekTo}
                tabId={currentTab.tabId}
                src={externalVideoSrc}
              />
            </div>
          );
        }

      case "webpage":
        if (externalWebpage) {
          return <ExternalWebpage src={externalWebpage} />;
        }

        return (
          <div className="placeholder-container">
            <p className="text-center text-gray-500">Loading external src</p>
          </div>
        );

      default:
        return (
          <div className="placeholder-container">
            
            <p className="text-center text-gray-500">No boards.e</p>
          </div>
        );
    }
  };

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div
        style={{ height: "40px", border: "1px solid #ccc", background: "#fff" }}
      >
        <TabHeader
          onTabCreate={onTabCreate}
          onTabSelect={onTabSelect}
          onTabClose={onTabClose}
        />
      </div>
      <div style={{ flex: "1 1 auto", overflow: "auto" }}>
        {renderContent()}
      </div>
    </div>
  );
};

const ExcalidrawApp = () => {
  return <ExcalidrawWrapper />;
};

export default ExcalidrawApp;
