import IconButton from "@mui/material/IconButton";

import React from "react";

import { useSetAtom } from "jotai";
import { isParticipantListVisibleAtom } from "../../../store/participantList";
import { Chat, ExitToApp } from "@mui/icons-material";
import { isExitModalOpen } from "../../../store/session";

function ExitIcon() {

  const togglePeopleList = useSetAtom(isExitModalOpen); // Set the atom to toggle visibility

  return (
    <IconButton
      style={{
        // border: "1px solid #ccc",
        background: "#ccc",
        marginLeft: "5px",
      }}
      size="large"
      onClick={() => togglePeopleList(prev => !prev)} // Toggle the visibility 
    >
      <ExitToApp />
      
    </IconButton>
  );
}

export default ExitIcon;
