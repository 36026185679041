import { useAtom, useAtomValue } from "jotai"; // useAtom to read and update the visibility atom
import "./ChatComponent.scss"; // Ensure this file contains the necessary styles
import React, { useEffect, useRef, useState } from "react";
import { IconButton } from "@mui/material";

import Close from "@mui/icons-material/Close";
import { currentSessionAtom, isChatVisibleAtom } from "../../store/session";

import { MessageType } from "../../types/chat";
import { addMessage, chatListAtom, Message } from "../../store/chat";
import { syncData } from "../../utils/roomClient";
import { getParticipant } from "../../store/participantList";

export const ChatComponent = () => {
  const [isVisible, setIsVisible] = useAtom(isChatVisibleAtom); // Read and write visibility atom

  const messages = useAtomValue(chatListAtom);
  const [newMessage, setNewMessage] = useState("");
  const messageEndRef = useRef<HTMLDivElement | null>(null);

  const currentSession = useAtomValue(currentSessionAtom);

  useEffect(() => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const handleSend = () => {
    if (newMessage.trim()) {
      const mes: Message = {
        messageId: Math.random().toString(),
        sender: currentSession?.user?.userId!,
        message: newMessage.trim(),
        timestamp: Date.now(),
        type: "text",
      };

      syncData.SendNewMessage(
        currentSession?.room.roomId!,
        currentSession?.user?.userId!,
        mes,
      );
      addMessage(mes);
      setNewMessage("");
    }
  };

  return (
    <>
      <div className={`chat-container ${isVisible ? "visible" : ""}`}>
        <div className="chat-header">
          <h2>Chats</h2>
          <IconButton onClick={() => setIsVisible(false)}>
            <Close />
          </IconButton>
        </div>

        <div className="message-container">
          <div className="message-list">
            {messages?.map((msg) => (
              <div
                key={msg.messageId}
                className={`message ${
                  msg.sender === currentSession?.user?.userId!
                    ? "own-message"
                    : "other-message"
                }`}
              >
                <div className="message-header">
                  {msg.sender !== currentSession?.user?.userId! && (
                    <span className="sender-name">
                      {getParticipant(msg.sender)?.name}
                    </span>
                  )}
                  <span className="timestamp">
                    {new Date(msg.timestamp).toLocaleTimeString()}
                  </span>
                </div>
                <div className="message-content">{msg.message}</div>
              </div>
            ))}
            <div ref={messageEndRef} />
          </div>
          <div className="chat-input">
            <input
              type="text"
              value={newMessage}
              placeholder="Type a message"
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyDown={(e) => (e.key === "Enter" ? handleSend() : null)}
            />
            <button onClick={handleSend}>Send</button>
          </div>
        </div>
      </div>

      {isVisible && (
        <div className="overlay" onClick={() => setIsVisible(false)}></div>
      )}
    </>
  );
};
