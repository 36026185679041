import React, { useEffect, useState, useRef } from "react";
import {
  createLocalAudioTrack,
  createLocalTracks,
  createLocalVideoTrack,
  LocalAudioTrack,
  LocalVideoTrack,
  Room,
} from "livekit-client";
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
  IconButton,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import {
  Mic,
  Videocam,
  MicOff,
  VideocamOff,
  VolumeUp,
} from "@mui/icons-material";
import "./DeviceTestPage.scss";
import { appJotaiStore } from "../../app-jotai";
import { currentSessionAtom, deviceManagerAtom, updateAudioDevices, updateAudioOutputDevices, updateSelectedAudioInputDevice, updateSelectedAudioOutputDevice, updateSelectedVideoInputDevice, updateVideoDevices } from "../../store/session";
import { fetchSessionState } from "../../api";
import { enqueueSnackbar } from "notistack";
import { useAtom, useAtomValue } from "jotai";

const rooms = [
  { name: "Room 1", id: "room1" },
  { name: "Room 2", id: "room2" },
  { name: "Room 3", id: "room3" },
  { name: "Room 4", id: "room4" },
  { name: "Room 5", id: "room5" },
  { name: "Room 6", id: "room6" },
  { name: "Room 7", id: "room7" },
  { name: "Room 8", id: "room8" },
];

const nameToId = (name: string) => {
  return name
    .toLowerCase()
    .replace(/[^a-z0-9]/g, "-") // Replace all non-alphanumeric characters with dashes
    .replace(/-+/g, "-") // Remove consecutive dashes
    .replace(/^-|-$/g, ""); // Trim leading or trailing dashes
};

const DeviceTestPage = () => {

  const [loaded, setLoaded] = useState(false);


  const deviceManger = useAtomValue(deviceManagerAtom)

  const selectedAudioInput = deviceManger.selectedAudioInput;
  const selectedVideoInput = deviceManger.selectedVideoInput ;
  const selectedAudioOutput = deviceManger.selectedAudioOutput;

  const audioInputs = deviceManger.audioDevices;
  const audioOutputs = deviceManger.audioOutputDevices;
  const videoInputs =deviceManger.videoDevices;

  const [name, setName] = useState<string>("");
  const [room, setRoom] = useState<string>(rooms[0].id);

  useEffect(() => {
    // new SpeedTest().onFinish = results => console.log("speed test...",results.getSummary());

    const audioInput = localStorage.getItem("selectedAudioInput") ?? "";
    const videoInput = localStorage.getItem("selectedVideoInput") ?? "";
    const audioOutput = localStorage.getItem("selectedAudioOutput") ?? "";

    updateSelectedAudioInputDevice(audioInput);
    updateSelectedAudioOutputDevice(audioOutput);
    updateSelectedVideoInputDevice(videoInput);

    setLoaded(true);
  }, []);

  useEffect(() => {
    if (!loaded) return;

    localStorage.setItem("selectedAudioInput", selectedAudioInput);
  }, [selectedAudioInput]);

  useEffect(() => {
    if (!loaded) return;

    localStorage.setItem("selectedAudioOutput", selectedAudioOutput);
  }, [selectedAudioOutput]);

  useEffect(() => {
    if (!loaded) return;
    localStorage.setItem("selectedVideoInput", selectedVideoInput);
  }, [selectedVideoInput]);

  const [audioPermissionGranted, setAudioPermissionGranted] = useState<
    boolean | null
  >(null);
  const [videoPermissionGranted, setVideoPermissionGranted] = useState<
    boolean | null
  >(null);

  const [audioTrack, setAudioTrack] = useState<LocalAudioTrack | null>(null);
  const [videoTrack, setVideoTrack] = useState<LocalVideoTrack | null>(null);

  const [audioMuted, setAudioMuted] = useState<boolean>(false);
  const [videoMuted, setVideoMuted] = useState<boolean>(false);

  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (!loaded) return;

    const fetchDevices = async () => {
      try {
        const devices = await Room.getLocalDevices("videoinput");

        updateVideoDevices(devices);
        console.log("selected video input",selectedVideoInput);

        if(selectedVideoInput == "") {
          console.log("video ooutput device...",devices[0].deviceId)
          updateSelectedVideoInputDevice(devices[0].deviceId);
        }

        // appJotaiStore.set(currentSessionAtom,{...session,videoDevices:devices});

       console.log("video devices update hua...", devices);

      

        const videoTrack = await createLocalVideoTrack({
          deviceId: selectedVideoInput,
        });
        setVideoPermissionGranted(true);
        setVideoTrack(videoTrack);
      } catch (err) {
        setVideoPermissionGranted(false);
      }
    };

    fetchDevices();

    if (localStorage.getItem("name")) {
      setName(localStorage.getItem("name")!);
    }

    if (localStorage.getItem("room")) {
      setRoom(localStorage.getItem("room")!);
    }
  }, [loaded]);

  useEffect(() => {
    if (!loaded) return;

    const fetchDevices = async () => {
      try {
        const audioDevices = await Room.getLocalDevices("audioinput");

        updateAudioDevices(audioDevices);
        if(!selectedAudioInput) {
          updateSelectedAudioInputDevice(audioDevices[0].deviceId);
        }
        // setSelectedAudioInput(audioDevices[0].deviceId);
        const audioTrack = await createLocalAudioTrack({
          deviceId: selectedAudioInput,
        });
        setAudioPermissionGranted(true);
        setAudioTrack(audioTrack);
      } catch (err) {
        setAudioPermissionGranted(false);
      }
    };
    fetchDevices();
  }, [loaded]);

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const audioOutput = await Room.getLocalDevices("audiooutput");
        updateAudioOutputDevices(audioOutput);
        if(!selectedAudioOutput) {
          updateSelectedAudioOutputDevice(audioOutput[0].deviceId)
        }
        // updateSelectedAudioOutputDevice(audioOutput[0].deviceId);
      } catch (err) {
        // setVideoPermissionGranted(false);
      }
    };
    if (loaded) fetchDevices();
  }, [loaded]);

  // Attach video track to video element
  useEffect(() => {
    if (videoTrack && videoRef.current) {
      videoTrack.attach(videoRef.current);
    }

    return () => {
      // if(videoTrack && videoRef.current){
      // videoTrack?.detach(videoRef.current);
      // videoRef.current.srcObject = null;
      // setVideoTrack(null);
      // } /
    };
  }, [videoTrack]);

  const toggleAudio = () => {
    enqueueSnackbar("Microphone muted.", { variant: "info" });
    setAudioMuted(!audioMuted);
    if (audioTrack) {
      audioMuted ? audioTrack.unmute() : audioTrack.mute();
    }
  };

  useEffect(() => {
    const updateVideoTrack = async () => {
      if (selectedVideoInput) {
        if (videoTrack) {
          console.log("video device input changed.. ",selectedVideoInput)
          videoTrack.setDeviceId(selectedVideoInput);
        }

        

        // const newVideoTrack = await createLocalVideoTrack({
        //   deviceId: selectedVideoInput,
        // });
        // setVideoTrack(newVideoTrack);
      }
    };
    updateVideoTrack();
  }, [selectedVideoInput]); // Re-create track when video input changes

  const toggleVideo = () => {
    setVideoMuted(!videoMuted);
    if (videoTrack) {
      videoMuted ? videoTrack.unmute() : videoTrack.mute();
    }
  };

  const testSpeaker = () => {
    console.log("play an audio here..");
    // const audio = new Audio("/test-audio.mp3");
    // audio.play();
  };

  const handleContinue = async () => {
    if (!name || !room) {
      return;
    }

    // Properly stop and detach the video track
    if (videoTrack) {
      videoTrack.detach(); // Detach from any video elements
      videoTrack.stop(); // Stop the track
      videoTrack.mediaStreamTrack.stop(); // Ensure the underlying track is stopped
      setVideoTrack(null); // Clear the track reference
    }

    // Similarly for audio track
    if (audioTrack) {
      audioTrack.stop();
      audioTrack.mediaStreamTrack.stop();
      setAudioTrack(null);
    }

    localStorage.setItem("name", name);
    localStorage.setItem("room", room);

    const session = await fetchSessionState(room, nameToId(name));

    console.log("got session from database..", session);
    // session.user.userId
    session.user.audioEnabled = !audioMuted;
    session.user.videoEnabled = !videoMuted;
    // session.selectedAudioInput = selectedAudioInput;
    // session.selectedAudioOutput = selectedAudioOutput;
    // session.selectedVideoInput = selectedVideoInput;
    console.log("got session from updated..", session);

    appJotaiStore.set(currentSessionAtom,session)
  };

  const requestPermissions = async () => {
    try {
      const tracks = await createLocalTracks({
        audio: {
          deviceId: selectedAudioInput,
        },
        video: {
          deviceId: selectedVideoInput,
        },
      });
      setAudioTrack(
        tracks.find((track) => track.kind === "audio") as LocalAudioTrack,
      );
      setVideoTrack(
        tracks.find((track) => track.kind === "video") as LocalVideoTrack,
      );
      setAudioPermissionGranted(true);
      setVideoPermissionGranted(true);
    } catch (err) {
      setAudioPermissionGranted(false);
      setVideoPermissionGranted(false);
    }
  };

  return (
    <div className="device-test-page">
      <div className="test-box">
        {/* Left Column: Device Test */}
        <div className="left-column">
          <div className="video-container">
            <div className="video-box">
              {videoTrack ? (
                <video
                  autoPlay
                  muted={audioMuted}
                  ref={videoRef}
                  className={`video-element ${videoMuted ? "video-muted" : ""}`}
                />
              ) : (
                <div className="avatar-placeholder" />
              )}
              <div className="video-controls">
                <IconButton
                  onClick={toggleAudio}
                  className="transparent-button"
                >
                  {audioMuted ? <MicOff /> : <Mic />}
                </IconButton>
                <IconButton
                  onClick={toggleVideo}
                  className="transparent-button"
                >
                  {videoMuted ? <VideocamOff /> : <Videocam />}
                </IconButton>
              </div>
            </div>
            <div className="device-selection">
              {/* Audio Input */}
              <FormControl
                size="small"
                fullWidth
                error={!audioPermissionGranted}
              >
                <Select
                  value={selectedAudioInput}
                  onChange={(e) => updateSelectedAudioInputDevice(e.target.value)}
                  input={
                    <OutlinedInput
                      startAdornment={
                        <InputAdornment position="start">
                          <Mic />
                        </InputAdornment>
                      }
                    />
                  }
                >
                  {audioPermissionGranted ? (
                    audioInputs.map((input) => (
                      <MenuItem key={input.deviceId} value={input.deviceId}>
                        {input.label || `Microphone ${input.deviceId}`}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="" disabled>
                      Microphone access denied
                    </MenuItem>
                  )}
                </Select>
              </FormControl>

              {/* Video Input */}
              <FormControl
                fullWidth
                size="small"
                error={!videoPermissionGranted}
              >
                <Select
                  value={selectedVideoInput}
                  onChange={(e) => updateSelectedVideoInputDevice(e.target.value)}
                  input={
                    <OutlinedInput
                      startAdornment={
                        <InputAdornment position="start">
                          <Videocam />
                        </InputAdornment>
                      }
                    />
                  }
                >
                  {videoPermissionGranted ? (
                    videoInputs.map((input) => (
                      <MenuItem key={input.deviceId} value={input.deviceId}>
                        {input.label || `Camera ${input.deviceId}`}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem selected value="" disabled>
                      Camera access denied
                    </MenuItem>
                  )}
                </Select>
              </FormControl>

              {/* Audio Output */}
              <FormControl fullWidth size="small">
                <Select
                  value={selectedAudioOutput}
                  onChange={(e) => updateSelectedAudioOutputDevice(e.target.value)}
                  input={
                    <OutlinedInput
                      startAdornment={
                        <InputAdornment position="start">
                          <VolumeUp />
                        </InputAdornment>
                      }
                    />
                  }
                >
                  {audioOutputs.map((output) => (
                    <MenuItem key={output.deviceId} value={output.deviceId}>
                      {output.label || `Speakers ${output.deviceId}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                variant="outlined"
                onClick={() => console.log("Test Speakers")}
                startIcon={<VolumeUp />}
              >
                Test Speakers
              </Button>
            </div>
          </div>
        </div>

        {/* Right Column: Name, Room and Continue Button */}
        <div className="right-column">
          <div className="name-room-container">


            <div style={{width:"100%", textAlign:"center", marginBottom:"5px"}}>
              <span style={{fontSize:"30px"}}>What's your name?</span>
            </div>

            <TextField
              variant="outlined"
              placeholder="Your Name"
              fullWidth
              value={name}
              size="medium"
              onChange={(e) => setName(e.target.value)}
              // style={{marginBottom:"10px"}}
            />

            <Select
              value={room}
              onChange={(e) => setRoom(e.target.value)}
              size="small"
            >
              {rooms.map((output) => (
                <MenuItem key={output.id} value={output.id}>
                  {output.name}
                </MenuItem>
              ))}
            </Select>

            <Button
              onClick={handleContinue}
              variant="contained"
              color="primary"
              fullWidth
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceTestPage;
