import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { useAtom } from "jotai";
import React from "react";
import { isExitModalOpen } from "../../store/session";

export const ExitComponent = () => {
  const [isModalOpen, setIsModalOpen] = useAtom(isExitModalOpen);

  const onClose = () => {
    setIsModalOpen(false);
  };

  const onLeave = () => {
    // Leave the classroom
    setIsModalOpen(false);
  };

  const onExit = () => {
    // Exit the classroom
    setIsModalOpen(false);
  };

  return (
    <Dialog open={isModalOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="h6">Exit Classroom</Typography>
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Are you sure you want to leave the classroom? You can always join back later.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button onClick={onLeave} color="primary" variant="contained" sx={{ width: "48%" }}>
          Leave
        </Button>
        <Button onClick={onExit} color="secondary" variant="outlined" sx={{ width: "48%" }}>
          Exit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
