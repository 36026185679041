import Videocam from "@mui/icons-material/Videocam";
import VideocamOff from "@mui/icons-material/VideocamOff";
import { Box, CircularProgress, FormControl, InputAdornment, MenuItem, OutlinedInput, Select } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { createLocalVideoTrack, Room, Track } from "livekit-client";

import { useEffect, useState } from "react";
import {
  getMediaServerConn,
  getMediaServerConnRoom,
} from "../../../utils/livekitClient";
import React from "react";
import { getDevices, getWebcamResolution } from "../../../helpers/utils";
import { participantListAtom } from "../../../store/participantList";
import { useAtom, useAtomValue } from "jotai";
import { currentSessionAtom, openDeviceListAtom } from "../../../store/session";
import { ArrowDropUp } from "@mui/icons-material";
function VideoIcon() {
  // const [videoEnabled, setVideoEnabled] = useState(false);

  const currentSession = useAtomValue(currentSessionAtom);
  // const currentUser = useAtomValue(participantListAtom).get(currentSession?.user.userId!);
  // const [showDeviceSelector, setShowDeviceSelector] = useState(false); // State to show/hide device selector div
  const [videoInputDevices, setVideoInputDevices] = useState<MediaDeviceInfo[]>([]);
  const [selectedVideoInput, setSelectedVideoInput] = useState<string>("");
  const [openDeviceList, setOpenDeviceList] = useAtom(openDeviceListAtom); 

  useEffect(() => {
    const selectedAudioInput = localStorage.getItem("selectedVideoInput") ?? "";
    setSelectedVideoInput(selectedAudioInput);

    const fetchAudioDevices = async () => {
      const audioDevices = await Room.getLocalDevices("videoinput");
      setVideoInputDevices(audioDevices);
    };

    fetchAudioDevices();
  }, []);

  const conn = getMediaServerConn();

  useEffect(() => {
    const check = async () => {
      if (currentSession?.user.controls?.video) {
        if (currentSession.user.videoEnabled) {
          console.log("needs to publish video");
          await conn.publishTrack(false, true);
        }
      } else if (!currentSession?.user.controls?.video) {
        if (currentSession?.user.videoEnabled) {
          // need to unpublish the cam as permission revoked.
          console.log("need to unpublish cam as permission revoked.");
          conn.unpublishLocalVideoTrack();
        }
      }
    };
    check();
  }, [currentSession?.user.controls?.video]);

  const [loading, setLoading] = useState(false);
  const toggleVideo = async () => {
    setLoading(true);

    if (currentSession?.user?.videoEnabled) {
      if (currentSession.user.isVideoMuted) {
        conn.unmuteVideo();
      } else {
        conn.muteVideo();
      }
    } else {
      // const resolution = getWebcamResolution();
      await conn.publishTrack(true, true);
    }
    setLoading(false);
  };

  const toggleDeviceSelector = () => {
    setOpenDeviceList(openDeviceList === 'video' ? null : 'video');
  };

  const handleDeviceChange = (deviceId: string) => {
    conn.updateDeviceId("videoinput",deviceId);
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#ddd", // Shared background color for both icons
          borderRadius: "50px", // Rounded corners
          // padding: "4px", // Padding around the icons
          marginLeft: "5px",

        }}
        display="flex"
        alignItems="center"
      >
        <IconButton
          style={{
            // background: "#ccc",
            marginRight: "-10px", // Negative margin to attach the arrow to the mic icon
          }}
          onClick={toggleDeviceSelector}
          size="large"
        >
          <ArrowDropUp />
        </IconButton>
        <IconButton
          style={{
            background: "#ccc",
          }}
          disabled={loading || !currentSession?.user.controls?.video}
          onClick={toggleVideo}
          size="large"
          color={
            currentSession?.user?.videoEnabled &&
            !currentSession?.user?.isVideoMuted
              ? "primary"
              : "error"
          }
        >
          {loading ? (
            <CircularProgress size={22} />
          ) : currentSession?.user?.videoEnabled &&
            !currentSession?.user?.isVideoMuted ? (
            <Videocam />
          ) : (
            <VideocamOff />
          )}
        </IconButton>
      </Box>

      {openDeviceList == "video" && (
        <Box
       
          style={{
            position: "absolute",
            backgroundColor: "#eee",
            padding: "10px",
            // boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            borderRadius: "4px",
            bottom: "60px", // Position it 50px above the button
            // transform: "translateY(-100%)", // Move the box above the button
            zIndex: 1000,
            gap: "10px",
          }}
        >
          <FormControl size="small" fullWidth>
            <Select
              id="audio-device-select"
              value={selectedVideoInput}
              size="small"
              MenuProps={{
                anchorOrigin: {
                  vertical: "top", // Dropdown will anchor from the top of the select box
                  horizontal: "center",
                },
                transformOrigin: {
                  vertical: "bottom", // Dropdown opens upwards, anchored to the bottom of the select box
                  horizontal: "center",
                },
                PaperProps: {
                  style: {
                    zIndex: 1300, // Ensure the dropdown appears above other elements
                  },
                },
                disableScrollLock: true, // Prevent scroll lock when dropdown opens
              }}
              input={
                <OutlinedInput
                  startAdornment={
                    <InputAdornment position="start">
                      <Videocam />
                    </InputAdornment>
                  }
                />
              }
              onChange={(e)=>handleDeviceChange(e.target.value)}
            >
              {videoInputDevices.length > 0 ? (
                videoInputDevices.map((device) => (
                  <MenuItem key={device.deviceId} value={device.deviceId}>
                    {device.label || `Camera ${device.deviceId}`}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No audio devices found</MenuItem>
              )}
            </Select>
          </FormControl>

         
        </Box>
      )}
    </>
  );
}

export default VideoIcon;
