import { useRef, useEffect } from "react";
import { TabMetaData } from "../../../types/tabs";

const usePreviousTab = (previousTab:TabMetaData | null) => {
    const ref = useRef<TabMetaData | null>(null);
    useEffect(()=>{
      ref.current = previousTab;
    });
  
    return ref.current;
  }



export default usePreviousTab;
