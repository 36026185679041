import { MIME_TYPES } from "../../../../packages/excalidraw/constants";
import { decompressData } from "../../../../packages/excalidraw/data/encode";
import type { FileId } from "../../../../packages/excalidraw/element/types";
import { ExcalidrawElement } from "../../../../packages/excalidraw/element/types";
import type {
  BinaryFileData,
  BinaryFileMetadata,
  DataURL,
} from "../../../../packages/excalidraw/types";
// import * as AWS from "aws-sdk";
import { FILE_CACHE_MAX_AGE_SEC } from "../../../app_constants";
import type { SyncableExcalidrawElement } from ".";
import { getSyncableElements } from ".";
import axios from "axios";
import { restoreElements } from "../../../../packages/excalidraw";
import { Page, Tab, TabMetaData } from "../../../types/tabs";
import { createTabMetadata, Tabs } from "../../../store/tabList";
import { ROOM_API } from "../../../constants/api";

export const loadFilesFromStorage = async (
  prefix: string,
  decryptionKey: string,
  filesIds: readonly FileId[],
) => {
  const loadedFiles: BinaryFileData[] = [];
  const erroredFiles = new Map<FileId, true>();

  await Promise.all(
    [...new Set(filesIds)].map(async (id) => {
      try {
        const params = {
          Bucket: "img.simpech.com", // Replace with your S3 bucket name
          Key: `${prefix}/${id}`, // Construct the object key using the prefix and file ID
        };

        // const response = await getS3().getObject(params).promise();

        // if (response.Body) {
        //   const arrayBuffer = response.Body as ArrayBuffer;

        //   const { data, metadata } = await decompressData<BinaryFileMetadata>(
        //     new Uint8Array(arrayBuffer),
        //     {
        //       decryptionKey,
        //     },
        //   );

        //   const dataURL = new TextDecoder().decode(data) as DataURL;

        //   loadedFiles.push({
        //     mimeType: metadata.mimeType || MIME_TYPES.binary,
        //     id,
        //     dataURL,
        //     created: metadata?.created || Date.now(),
        //     lastRetrieved: metadata?.created || Date.now(),
        //   });
        // } else {
        //   erroredFiles.set(id, true);
        // }
      } catch (error: any) {
        erroredFiles.set(id, true);
        console.error(error);
      }
    }),
  );

  return { loadedFiles, erroredFiles };
};

export const saveFilesToStorage = async ({
  prefix,
  files,
}: {
  prefix: string;
  files: { id: FileId; buffer: Uint8Array }[];
}) => {
  // const firebase = await loadFirebaseStorage();

  const erroredFiles = new Map<FileId, true>();
  const savedFiles = new Map<FileId, true>();
  console.log("save hone aayah hahaiaiiaiai");
  await Promise.all(
    files.map(async ({ id, buffer }) => {
      try {
        console.log("preparing iamge uplaod...");

        const params = {
          Bucket: "img.simpech.com", // S3 bucket name
          Key: `${prefix}/${id}`, // S3 object key (path)
          Body: new Blob([buffer], { type: "application/octet-stream" }), // Use Blob instead of Buffer
          ContentType: "application/octet-stream", // MIME type
          CacheControl: `public, max-age=${FILE_CACHE_MAX_AGE_SEC}`, // Cache control settings
        };
        // await uploadToS3(buffer,id,"my-prefix");

        console.log("uploading on s33...");

        // await getS3().upload(params).promise();

        savedFiles.set(id, true);
      } catch (error: any) {
        console.log("error hile uploading to s3...", error);
        erroredFiles.set(id, true);
      }
    }),
  );

  return { savedFiles, erroredFiles };
};

export const loadFromBackend = async (
  roomId: string,
  // socket: Socket | null,
): Promise<{tabs:TabMetaData[]; currentTabIndex:number}> => {
  const tabMeta = createTabMetadata();
  tabMeta.tabId = "default-tab-id";
  let tabs: TabMetaData[] = [];
  let currentTabIndex = 0;

  try {
    const resp = await axios.get(
      `${ROOM_API}/classroom/${roomId}/whiteboard`,
    );

    console.log("resp from whiteboard rendpoint..",resp);

    const backendData = resp.data.data;

    if(backendData) {
      tabs = [];
      backendData.forEach((tab, index) => {
        if (!tab.title) {
          tab.title = `Board ${index + 1}`;
        }

        if(tab.active) {
          currentTabIndex = index;
        }
  
        tab.pages.forEach((page) => {
          page.points = getSyncableElements(restoreElements(page.points, null));
        });
        const tabMetadata: TabMetaData = {
          title: tab.title,
          type: tab.type,
          tabId: tab.tabId,
          pageIndex:0
        };
  
        console.log("tab from database..",tab);
  
        Tabs.set(tab.tabId, tab);
  
        tabs.push(tabMetadata);
      });
    }
   
  } catch (error) {
    const page: Page = {
      points: [],
      index: 0,
    };
   
    const tab: Tab = {
      type: tabMeta.type,
      pages: [page],
      tabId: tabMeta.tabId,
    };
    Tabs.set(tab.tabId, tab);
  }

  

  // const storedElements = resp.data.data.data;
  // console.log("type of stored element: ", typeof storedElements);
  // const elements = getSyncableElements(restoreElements(storedElements, null));
  // console.log("got elemtnnnn", elements);
  // console.log("got type of", typeof elements);
  // if (socket) {
  //   FirebaseSceneVersionCache.set(socket, elements);
  // }
  // const tab = createTab();

  return {tabs,currentTabIndex};
};

// let s3: AWS.S3 | null = null;

// const getS3 = (): AWS.S3 => {
//   if (!s3) {
//     console.log("s3 cred created....");
//     s3 = new AWS.S3({
//       accessKeyId: import.meta.env.VITE_APP_AWS_ACCESS_KEY, // Replace with your access key
//       secretAccessKey:
//       import.meta.env.VITE_APP_AWS_SECRET_ACCESS, // Replace with your secret key
//       endpoint: import.meta.env.VITE_APP_AWS_S3_ENDPOINT, // Replace with your S3-compatible storage endpoint
//       region: import.meta.env.VITE_APP_AWS_REGION, // Replace with your region if necessary
//       // s3ForcePathStyle: true, // Required for some S3-compatible services
//       // signatureVersion: "v4", // Signature version for S3 (v4 is common, but check your provider's documentation)
//     });
//   }
//   return s3;
// };
