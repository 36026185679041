import IconButton from "@mui/material/IconButton";

import React from "react";

import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { isParticipantListVisibleAtom } from "../../../store/participantList";
import ViewSidebarIcon from '@mui/icons-material/ViewSidebar';
import { currentSessionAtom, LayoutSettings, sessionLayoutAtom } from "../../../store/session";
import { syncData } from "../../../utils/roomClient";
import { appJotaiStore } from "../../../app-jotai";
function LayoutIcon() {

  const [layout,setlayout] = useAtom(sessionLayoutAtom); // Set the atom to toggle visibility

  
  const session = useAtomValue(currentSessionAtom)
  return (
    <IconButton
      style={{
        // border: "1px solid #ccc",
        background: "#ccc",
        marginLeft: "5px",
      }}
      size="large"
      onClick={() => {
        const layout :LayoutSettings = {
          whiteboard:80,
          video:20,
          chat:0
        }
        setlayout(layout);

        syncData.LayoutChange(session?.room.roomId!, session?.user.userId!,layout)

      }} // Toggle the visibility 
    >
      <ViewSidebarIcon />
      
    </IconButton>
  );
}

export default LayoutIcon;
